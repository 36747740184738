import React, { useEffect, useState } from 'react';
import {
    Box,
    Flex,
    Avatar,
    Icon,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    useTheme,
} from '@chakra-ui/react';
import { getConversationMessages } from '../../../api/Calls/messages/getMessages';
import { useLogto } from '@logto/react';
import useToastError from '../../../hooks/useToastError';
import { useGetTokenWithOrganization } from '../../../hooks/useGetTokenWithOrganization';
import { CustomBox, Text, Button } from '@canaia/ui-kit';
import {ConversationModalProps, Message} from './types';
import jsPDF from "jspdf";
import PositiveIcon from '../../../icons/feedback/positive';
import NegativeIcon from '../../../icons/feedback/negative';

const ConversationModal: React.FC<ConversationModalProps> = ({
    isOpen,
    onClose,
    id,
    conversation
}) => {
    const { fetchUpdatedToken } = useGetTokenWithOrganization();
    const {signOut} = useLogto();
    const showError = useToastError();
    const [conversationMessages, setConversationMessages] = useState<Message[]>([]);
    const theme = useTheme();

    useEffect(() => {
        loadMessages(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const dynamicData = [
        { title : "Name", option: conversation.user.name },
        { title : "Assistant", option: conversation.assistant.name },
        { title : "Conversation", option: conversation.title },
        { title : "Date", option: conversation.updated_at },
    ];

    const loadMessages = async (conversationId: number) => {
        try {
            const token = await fetchUpdatedToken();
            if (!token) return;
            
            const data = await getConversationMessages(
                token,
                conversationId,
                signOut,
                showError,
                { page: 1, size: 50 }
            );
            setConversationMessages(data.items);
        } catch {
            showError('Error al cargar los mensajes');
        }
    };

    const downloadAsPdf = () => {
        const doc = new jsPDF();
        let yPosition = 10; 

        conversationMessages.forEach((message) => {
            const text = message.is_user
                ? `- ${conversation.user.name}: "${message.content}"`
                : `- ${conversation.assistant.name}: "${message.content}"`;
            doc.text(text, 10, yPosition); 
            yPosition += 10;
            if (yPosition > 280) {
                doc.addPage(); 
                yPosition = 10;
            }
        });
    
        doc.save("conversacion.pdf");
    };
      
    return (
        <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
            <ModalOverlay />
            <ModalContent 
                borderRadius="24px" 
                bg="white" 
                minWidth="700px" 
                minHeight="385px"
                padding="24px"
            >
                <ModalHeader p={0} bg="white" borderTopRadius={24}>
                    <Box textAlign="left" mb="20px">
                        <Text text="Conversations" />
                    </Box>
                </ModalHeader>
                <ModalCloseButton onClick={onClose}/>

                <ModalBody p={0}>
                    <Box>
                        <Flex align="center" justify="space-between">
                            <CustomBox items={dynamicData} colorBackground="elevate.500" colorOption="white.100"/>
                        </Flex>
                        <Box 
                            bg="elevate.200"  
                            height="160px"
                            maxH="160px"
                            padding="24px"
                            overflowY="auto"
                            sx={{
                                "&::-webkit-scrollbar": {
                                    width: "5px", 
                                },
                                "&::-webkit-scrollbar-track": {
                                    background: "#f9f9f9",
                                    borderRadius: "4px",
                                },
                                "&::-webkit-scrollbar-thumb": {
                                    background: "#b3b3b3", 
                                    borderRadius: "4px",
                                },
                                "&::-webkit-scrollbar-thumb:hover": {
                                    background: "#999999", 
                                },
                            }}
                        >
                            {conversationMessages.map((message) => (
                                <Flex
                                    key={message.id}
                                    justify={message.is_user ? "flex-end" : "flex-start"}
                                >
                                    {!message.is_user && (
                                        <Flex 
                                            align="center"
                                            display="inline-flex" 
                                            justifyContent="center"
                                            mb={4}
                                            mt={4}
                                            px="15px"
                                            py="4px"
                                            border={message.feedback && !message.is_user ? "1px solid" : undefined}
                                            borderRadius={message.feedback && !message.is_user ? "50px" : undefined}
                                            borderColor={
                                                !message.is_user && 
                                            message.feedback === "positive" ?
                                                    "success.500" 
                                                    :   !message.is_user &&  message.feedback === "negative"
                                                        ? "danger.500"
                                                        : undefined
                                            }
                                            maxW="80%"
                                        >
                                            <Avatar
                                                bg="pink.500"
                                                mr={2}
                                                icon={
                                                    <Icon
                                                        viewBox="0 0 24 24"
                                                        color="white"
                                                        boxSize={4}
                                                    >
                                                        <path
                                                            d="M12 2C6.477 2 2 6.477 2 12c0 5.523 4.477 10 10 10s10-4.477 10-10c0-5.523-4.477-10-10-10zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"
                                                            fill="currentColor"
                                                        />
                                                    </Icon>
                                                }
                                            />
                                            <Box p={3} >
                                                <Text textAlign="left" fontSize="sm" text={message.content}/>
                                            </Box>
                                            {message.feedback && (
                                                <Box>
                                                    {message.feedback === "positive" ? <PositiveIcon color={theme.colors.success[400]} /> : <NegativeIcon color={theme.colors.danger[400]} />}
                                                </Box>
                                            )}
                                        </Flex>
                                    )}
                                    {message.is_user && (
                                        <Flex align="center" justify="flex-end">
                                            <Box
                                                bg="white"
                                                borderRadius="lg"
                                                p={3}
                                                maxW="80%"
                                                boxShadow="sm"
                                            >
                                                <Text fontSize="sm" text={message.content} />
                                            </Box>
                                            <Avatar bg="blue.500" ml={2} />
                                        </Flex>
                                    )}
                                </Flex>
                            ))}
                       
                        </Box>
                    </Box>
                </ModalBody>

                <ModalFooter justifyContent="center">
                    <Button text="Dowland" color="elevate.500" onClick={downloadAsPdf} />
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default ConversationModal;
