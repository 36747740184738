import React from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Image,
    Box,
    Text,
    HStack,
    IconButton,
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { getStatusColor } from '../utils';
import { KnowledgeBasesTableProps } from '../../../pages/KnoweledgeBases/KnowledgeBaseItem.types'
import imgUrlDefault from '../../../images/imagePerfil.png';
import Options from './options'
import { useTranslation } from 'react-i18next';
import FormattedDate from '../FomattedDate';

const KnowledgeBasesTable: React.FC<KnowledgeBasesTableProps> = ({
    data,
    currentPage,
    pageSize,
    totalItems,
    onPageChange,
    setUpdateData
}) => {
    const { t } = useTranslation();
    const totalPages = Math.ceil(totalItems / pageSize);
    
    return (
        <Box bg="white" rounded="md" shadow="md" p={4}>
            <Table variant="simple" >
                <Thead>
                    <Tr>
                        <Th color="black.500">{t('KnowledgeBases')}</Th>
                        <Th color="black.500">{t('description')}</Th>
                        <Th color="black.500">{t('status')}</Th>
                        <Th color="black.500">{t('lastMofificated')}</Th>
                        <Th />
                    </Tr>
                </Thead>
                <Tbody>
                    {data.map((item) => (
                        <Tr key={item.id}>
                            <Td>
                                <HStack spacing={2}>
                                    <Image
                                        src={item.icon ? item.icon : imgUrlDefault}
                                        alt={item.name}
                                        boxSize="40px"
                                        rounded="full"
                                    />
                                    <Text fontWeight="semibold">{item.name}</Text>
                                </HStack>
                            </Td>
                            <Td>{item.description}</Td>
                            <Td>
                                <Text color={getStatusColor(item.status || '')}>
                                    {item.status}
                                </Text>
                            </Td>
                            <Td>
                                <FormattedDate isoDate={item.updated_at} />
                            </Td>
                            <Td textAlign="right">
                                <Options item={item} setUpdateData={setUpdateData}/>
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>

            {totalPages > 1 && (
                <HStack justify="flex-end" spacing={2} mt={4}>
                    <IconButton
                        aria-label="Previous page"
                        icon={<ChevronLeftIcon />}
                        onClick={() => onPageChange(currentPage - 1)}
                        isDisabled={currentPage === 1}
                        variant="outline"
                        size="sm"
                    />
                    <Text>
                        {currentPage} / {totalPages}
                    </Text>
                    <IconButton
                        aria-label="Next page"
                        icon={<ChevronRightIcon />}
                        onClick={() => onPageChange(currentPage + 1)}
                        isDisabled={currentPage === totalPages}
                        variant="outline"
                        size="sm"
                    />
                </HStack>
            )}
           
        </Box>
    );
};

export default KnowledgeBasesTable;
