import axios, { AxiosResponse } from 'axios';
import type { AxiosError } from 'axios';

export const updateAssistantImage = async (
    token: string,
    assistant_id: number,
    file: File,
    signOut: () => Promise<void>,
    showToast: (message: string, status?: 'success' | 'error') => void,
): Promise<unknown> => {
    const formData = new FormData();
    formData.append('file', file);

    try {
        const response: AxiosResponse = await axios.put(
            `${import.meta.env.VITE_API_ENDPOINT}/api/assistants/${assistant_id}/image`,
            formData,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            }
        );
        return response.data;
    } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
            if (error.response?.data?.detail) {
                showToast(error.response.data.detail, 'error');
            }
            const axiosError = error as AxiosError;
            if (axiosError.response) {
                if (axiosError.response.status === 401) {
                    await signOut();
                    showToast('Sesión expirada. Por favor, inicia sesión nuevamente.', 'error');
                }
            } else {
                showToast('Hubo un error al actualizar la imagen.', 'error');
            }
        } else {
            showToast('Ocurrió un error inesperado al actualizar la imagen.', 'error');
        }
        throw error;
    }
};
