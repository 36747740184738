import { useEffect, useState } from 'react';
import {
    Box,
    Heading,
    Flex,
    InputGroup,
    Input,
    InputLeftElement,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { Spinner } from '@canaia/ui-kit';
import { getConversations } from '../../api/Calls/conversations/getConversations';
import { useGetTokenWithOrganization } from '../../hooks/useGetTokenWithOrganization';
import { useLogto } from '@logto/react';
import useToastError from '../../hooks/useToastError';
import ConversationsTable from '../../ui/components/TableConversations';
import { Conversation } from '../../ui/components/TableConversations/conversations.types';
import { useTranslation } from 'react-i18next';
import ShowModalConversation from '../../ui/components/ShowModalConversation';

function ConversationsPage() {
    const { t } = useTranslation();
    const { signOut } = useLogto();
    const showError = useToastError();
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [conversations, setConversations] = useState<Conversation[]>([]);
    const { fetchUpdatedToken } = useGetTokenWithOrganization();
    const [isLoading, setIsLoading] = useState(true);
    const [idConversation, setIdConversation] = useState<number | null>(null);
    const [showConversationModal, setShowConversationModal] = useState(false);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        loadConversations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    const loadConversations = async () => {
        try {
            const token = await fetchUpdatedToken();
            if (!token) {
                setIsLoading(false);
                return;
            }
            const params = { page: currentPage, size: pageSize, search: searchTerm };
            const data = await getConversations(token, signOut, showError, params);
            setTotalItems(data.total);
            setTotalPages(data.pages);
            setConversations(data.items);
        } catch {
            showError(t('HaveAErrorPliseTryAgainInParMinutes'));
        } finally {
            setIsLoading(false);
        }
    };

    const clickShowModal = (id: number) => {
        setIdConversation(id);
        setShowConversationModal(true)
    }


    return (
        <Box minH="100vh" p={4}>
            {isLoading && <Spinner />}
            {showConversationModal 
            && idConversation
            && <ShowModalConversation 
                id={idConversation}
                onClose={() => setShowConversationModal(false)}
                isOpen={showConversationModal}
                conversation={conversations.find(item => item.id === idConversation) || {} as Conversation}
            />}
            <Heading mb="40px" textAlign="left" fontSize="36px">
                {t('Conversations')}
            </Heading>
            <Flex justify="flex-end" align="center" mb={4}>
                <InputGroup w="300px" ml={4}>
                    <InputLeftElement pointerEvents="none">
                        <SearchIcon color="gray.400" />
                    </InputLeftElement>
                    <Input
                        placeholder={t('search') + '...'}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        bg={'white'}
                    />
                </InputGroup>
            </Flex>
            {conversations?.length === 0 ? (
                <Box>{t('Nodata')}</Box>
            ) : (
                <ConversationsTable
                    data={conversations ? conversations : []}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    totalItems={totalItems}
                    onPageChange={handlePageChange}
                    totalPages={totalPages}
                    clickShowModal={clickShowModal}
                />
            )}
        </Box>
    );
}

export default ConversationsPage;
