import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    en: {
        translation: {
            hello: "Hello",
            welcome: "Welcome",
            EditKnowledgeBase: "Edit Knowledge Base",
            Status : "Status",
            Active: "Active",
            Image: "Image",
            UploadImage : "Upload Image",
            enterTheNameOfYourDatabase: "Enter the name of your database",
            name: "Name",
            description: "Description",
            companyDescription: "Company Description",
            enterADescriptionOfThisDatabase: "Enter a description of this database.",
            conversationStarter: "Conversation Starter",
            helloImIrinaYourVirtualKnowledgeBaseHereToAssistYouWithAllMattersRelatedToYourCompensationAndBenefitsHowCanIHelpYouToday: "Hello, I’m Irina, your virtual Knowledge base here to assist you with all matters related to your compensation and benefits. How can I help you today?",
            addConversationStartersToHelpUsers: "Add conversation starters to help users.",
            behavior: "Behavior",
            YouAreGoingToBeHelpfulKnowledgeBaseYouAreGoingToBeConcise: "You are going to be helpful Knowledge base, you are going to be concise...",
            behaviorDefinesHowTheAIKnowledgeBaseActsAndRespondsInConversations: "Behavior defines how the AI knowledge base acts and responds in conversations.",
            behaviorDefinesHowTheAIKnowledgeBaseActsAndRespondsInConversationsItSetsTheAI: "Behavior defines how the AI Knowledge base acts and responds in conversations. It sets the AI's personality, knowledge base, and interaction style. Customize this to shape the AI's approach to suit your specific needs or preferences.",
            FrequentTopics: "Frequent Topics",
            IntroduceFrequentOrCommonTopicsThatUsersOftenAskSoTheyCanUseThemQuickly: "Introduce frequent or common topics that users often ask so they can use them quickly.",
            WhatAreYourOpeningHours: "What are your opening hours?",
            AreYouOpenOnSundays: "Are you open on Sundays?",
            ThisFieldIsForSpecifyingFrequentQuestions:"This field is for specifying frequent questions.",
            Back: "Back",
            Save: "Save",
            KnowledgeBases: "Agent AI",
            NewKnowledgeBase: "New Knowledge base",
            Chat: "Chat",
            Conversations: "Conversations",
            Files: "Files",
            Dashboard: "Dashboard",
            leaveItToCanaiaCreateYourFirstKnowledgeDatabase: "Leave it to Canaia, create your first knowledge database",
            CreateKnowledgeDatabase: "Create knowledge database",
            HaveAErrorPliseTryAgainInParMinutes: "There was an error. Please try again in a few minutes.",
            UploaderYourFiles: "Uploader your files",
            search: "Search",
            Nodata: "No data",
            AssistantCreatedSuccessfully: "Assistant created successfully!",
            signOut: "Sign Out",
            signIn: "Sign In",
            searchQuestionWithOutLetter: "Please, enter a search query.",
            FailedGetAuthenticationToken: "Failed to get authentication token",
            ErrorSearchingForAttendees: "Error searching for attendees",
            redirection: "Redirection",
            dangerZone: "Danger Zone",
            ThisActionCannotBeUndonePleaseMakeSureToSave: "This action cannot be undone, please make sure to save the necessary information before deleting.",
            delete: "Delete",
            lastMofificated: "Last Modified",
            deleteFile: "Delete File",
            AreYouSureYouWantToDeleteThisFile: "Are you sure you want to delete this file?",
            fileSploadedSuccessfully: "File uploaded successfully",
            UploadingFiles: "Uploading files",
            DragYourFilesHere: "Drag your files here",
            UploadFile: "Upload File",
            RemoveFile: "Remove file",
            next: "Next",
            ErrorDeletingFile: "Error deleting file",
            FileDeletedSuccessfully: "File deleted successfully",
            FileUpdatedSuccessfully: "File updated successfully",
            DownloadStartedSuccessfully:  "Download started successfully",
            editFile: "Edit File",
            dowland: "Dowland",
            ThisActionCannotBeUndonePleaseMakeSureToSaveTheNecessaryInformation:  "This action cannot be undone, please make sure to save the necessary information before deleting.",
            status: "Status",
            Assistant: "Assistant",
            PreviousPage: "Previous page",
            NextPage: "Next page",
            options: "Options",
            hasBeenDeletedSuccessfully: "has been deleted successfully",
            DeleteKnowledgeBase: "Delete Knowledge base",
            AreyouSureYouCanTUndoThisActionAfterwards: "Are you sure? You can't undo this action afterwards.",
            edit: "Edit",
            try: "Try",
        }
    },
    es: {
        translation: {
            hello: "Hola",
            welcome: "Bienvenido",
            EditKnowledgeBase: "Editar Base de Conocimiento",
            Status : "Estado",
            Active: "Activo",
            Image: "Imagen",
            UploadImage : "Subir Imagen",
            enterTheNameOfYourDatabase: "Ingresa el nombre de tu base de datos",
            name: "Nombre",
            description: "Descripción",
            companyDescription: "Descripción de la empresa",
            enterADescriptionOfThisDatabase: "Ingresa una descripción de esta base de datos.",
            conversationStarter: "Inicio de conversación",
            helloImIrinaYourVirtualKnowledgeBaseHereToAssistYouWithAllMattersRelatedToYourCompensationAndBenefitsHowCanIHelpYouToday: "Hola, soy Irina, tu base de conocimiento virtual aquí para ayudarte con todos los asuntos relacionados con tu compensación y beneficios. ¿Cómo puedo ayudarte hoy?",
            addConversationStartersToHelpUsers: "Agrega conversaciones para ayudar a los usuarios.",
            behavior: "Comportamiento",
            YouAreGoingToBeHelpfulKnowledgeBaseYouAreGoingToBeConcise: "Vas a ser una base de conocimiento útil, vas a ser conciso...",
            behaviorDefinesHowTheAIKnowledgeBaseActsAndRespondsInConversations: "El comportamiento define cómo actúa y responde la base de conocimiento de IA en las conversaciones.",
            behaviorDefinesHowTheAIKnowledgeBaseActsAndRespondsInConversationsItSetsTheAI: "El comportamiento define cómo actúa y responde la base de conocimiento de IA en las conversaciones. Establece la personalidad, la base de conocimiento y el estilo de interacción de la IA. Personaliza esto para dar forma al enfoque de la IA según tus necesidades o preferencias específicas.",
            FrequentTopics: "Temas frecuentes",
            IntroduceFrequentOrCommonTopicsThatUsersOftenAskSoTheyCanUseThemQuickly: "Introduce temas frecuentes o comunes que los usuarios suelen preguntar para que puedan usarlos rápidamente.",
            WhatAreYourOpeningHours: "¿Cuáles son tus horarios de atención?",
            AreYouOpenOnSundays: "¿Estás abierto los domingos?",
            ThisFieldIsForSpecifyingFrequentQuestions:"Este campo es para especificar preguntas frecuentes.",
            Back: "Atrás",
            Save: "Guardar",
            KnowledgeBases: "Agente IA",
            NewKnowledgeBase: "Nueva base de conocimiento",
            Chat: "Chat",
            Conversations: "Conversaciones",
            Files: "Archivos",
            Dashboard: "Tablero",
            leaveItToCanaiaCreateYourFirstKnowledgeDatabase: "Déjaselo a Canaia, crea tu primer base de datos de conocimiento",
            CreateKnowledgeDatabase: "Crear base de conocimiento",
            HaveAErrorPliseTryAgainInParMinutes: "Hubo un error. Por favor, inténtelo de nuevo en unos minutos.",
            UploaderYourFiles: "Subir tus archivos",
            search: "Buscar",
            Nodata: "No hay datos",
            AssistantCreatedSuccessfully: "¡Asistente creado con éxito!",
            signOut: "Cerrar sesión",
            signIn: "Iniciar sesión",
            searchQuestionWithOutLetter: "Por favor, ingrese una consulta de búsqueda.",
            FailedGetAuthenticationToken: "Error al obtener el token de autenticación",
            ErrorSearchingForAttendees: "Error al buscar asistentes",
            redirection: "Redirección",
            dangerZone: "Zona de peligro",
            ThisActionCannotBeUndonePleaseMakeSureToSave: "Esta acción no se puede deshacer, asegúrese de guardar la información necesaria antes de eliminar",
            delete: "Eliminar",
            lastMofificated: "Última modificación",
            deleteFile: "Eliminar archivo",
            AreYouSureYouWantToDeleteThisFile: "¿Estás seguro de que quieres eliminar este archivo?",
            fileSploadedSuccessfully: "Archivo subido con éxito",
            UploadingFiles: "Subiendo archivos",
            UploadFile: "Subir archivo",
            DragYourFilesHere: "Arrastra tus archivos aquí",
            RemoveFile: "Eliminar archivo",
            next: "Siguiente",
            ErrorDeletingFile: "Error al eliminar el archivo",
            FileDeletedSuccessfully: "Archivo eliminado con éxito",
            FileUpdatedSuccessfully: "Archivo actualizado con éxito",
            DownloadStartedSuccessfully:  "Descarga iniciada con éxito",
            editFile: "Editar archivo",
            dowland: "Descargar",
            ThisActionCannotBeUndonePleaseMakeSureToSaveTheNecessaryInformation:  "Esta acción no se puede deshacer, asegúrese de guardar la información necesaria antes de eliminar",
            status: "Estado",
            Assistant: "Asistente",
            PreviousPage: "Página anterior",
            NextPage: "Página siguiente",
            options: "Opciones",
            edit: "Editar",
            hasBeenDeletedSuccessfully: "ha sido eliminado exitosamente",
            DeleteKnowledgeBase: "Eliminar base de conocimiento",
            AreyouSureYouCanTUndoThisActionAfterwards: "¿Estás seguro? No podrás deshacer esta acción después.",
            try: "Probar",

        }
    }
};

i18n.use(initReactI18next).init({
    resources,
    lng: "es", // Initial language
    fallbackLng: "en", // Language in case there is no translation
    interpolation: {
        escapeValue: false, // react-i18next takes care of security
    }
});



export default i18n;
