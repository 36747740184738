import createApiClient from "../../ApiClients";

export interface UpdateAssistantParams {
    name?: string;
    description?: string;
    behavior?: string;
    examples?: string[];
    files?: number[];
    conversation_starter?: string;
  }

  
export const updateAssistant = async (
    token: string,
    signOut: () => Promise<void>,
    showToast: (message: string) => void,
    assistantId: number,
    assistantData: UpdateAssistantParams
) => {
    const apiClient = createApiClient(token, signOut, showToast);
    const response = await apiClient.patch(`/assistants/${assistantId}`, assistantData);

    return response.data;
};
