import createApiClient from '../ApiClients';

interface GetAssistantsParams {
  page?: number;  
  size?: number;
}

export const getAssistants = async (
    token: string,
    signOut: () => Promise<void>,
    showToast: (message: string) => void,
    { page = 1, size = 50 }: GetAssistantsParams = {}
) => {
    const apiClient = createApiClient(token, signOut, showToast);

    const response = await apiClient.get('/assistants', {
        params: {
            page,
            size,
        },
    });

    return response.data;
};
