import React, { useState } from 'react';
import { Flex } from '@chakra-ui/react';
import SidebarChat from '../../ui/components/SidebarChat';
import ChatContent from '../../ui/components/ChatContent';

const Chat: React.FC = () => {
    const [resetChat, setResetChat] = useState(false);
  
    const getAsistantSelected = (id: number) => {
        console.log('agarro el id del asistente selecionado para despues hacer cosas en el sidebar , es decir pasarselo como hijo al sidebar.', id)
    }
    const conversations = [
        {
            title: "Favorites",
            content: ['How many vacations do...', 'Lorem ipsum', 'litora']
        },
        {
            title: "Last Week",
            content: ['Last week conversation 1', 'Last week conversation 2']
        },
        {
            title: "Last Month",
            content: ['Last month conversation 1', 'Last month conversation 2']
        }
    ];



    return (
        <Flex>
            <SidebarChat items={conversations} colorIcon="elevate.500" setResetChat={setResetChat} />
            <ChatContent getAsistantSelected={getAsistantSelected} resetChat={resetChat} setResetChat={setResetChat} />
        </Flex>
    );
};

export default Chat;
