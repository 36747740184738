import React, { useEffect } from "react";
import {
    Box,
    Text,
    Flex,
} from "@chakra-ui/react";
import { StepOneProps } from "../types/steps.types";
import { PhotoUpload, Input} from "@canaia/ui-kit"; 
import { useTranslation } from "react-i18next";

const StepOne: React.FC<StepOneProps> = ({ onComplete, updateDataAssistant, initialData, updateImagePerfil, onIncomplete }) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (initialData.name.trim()) {
            onComplete();
        } else if(!initialData.name.trim()){
            onIncomplete();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialData]);

    const handleFileUpload = (image: File) => {
        if (image) {
            updateImagePerfil(image);
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        updateDataAssistant(name, value);
    };

    return (
        <Box>
            <Text fontSize="2xl" fontWeight="bold" mb={8} textAlign="start">
        Create a Knowledge Base
            </Text>
            <Flex alignItems="center" mb={4} justifyContent="space-between">
                <Flex mr="15px">
                    <PhotoUpload
                        description={(t('UploadImage'))}
                        iconColor="elevate.500"
                        onUpload={handleFileUpload}
                        h="205px"
                        w="205px"
                        initialImage={initialData.image}
                    />
                </Flex>
                <Flex width="100%" flexDir="column" justifyContent="space-between">
                    <Box mb="10px">
                        <Input 
                            label={t('name')}
                            info
                            infoText={"name of knowdle base"}
                            product="elevate"
                            isRequired
                            name="name"
                            placeholder="Placeholder"
                            value={initialData.name}
                            onChange={(value: string) =>
                                handleChange({ target: { name: "name", value: value } } as React.ChangeEvent<HTMLInputElement>)
                            }
                        />
                    </Box>

                    <Input
                        label={t('description')}
                        placeholder={"Description of your Knowledge base"}
                        info
                        infoText={"Enter a description of this database"}
                        product="elevate"
                        value={initialData.description}
                        onChange={(value: string) =>
                            handleChange({ target: { name: "description", value: value } } as React.ChangeEvent<HTMLInputElement>)
                        }
                        textArea={true}
                    />
                </Flex>
            </Flex>
        </Box>
    );
};

export default StepOne;
