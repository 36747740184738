import createApiClient from '../../ApiClients';

interface GetFilesParams {
  page?: number;  
  size?: number;
}

export const getFiles = async (
    token: string,
    signOut: () => Promise<void>,
    showToast: (message: string) => void,
    { page = 1, size = 50 }: GetFilesParams = {}
) => {
    const apiClient = createApiClient(token, signOut, showToast);
    const response = await apiClient.get('/files', {
        params: {
            page,
            size,
        },
    });

    return response.data;
};
