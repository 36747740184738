import React from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Icon,
    Box,
    Text,
    HStack,
    IconButton,
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { FilesTableProps } from './files.types.tsx';
import IconPdf from '../../../icons/pdfIcon.tsx';
import { StatusCustom } from "@canaia/ui-kit";
import  mapStatusToType  from "./utils.tsx";
import Options from './options.tsx';
import { useTranslation } from 'react-i18next';

const FilesTable: React.FC<FilesTableProps> = ({
    data,
    currentPage,
    pageSize,
    totalItems,
    onPageChange,
    setUpdateData
}) => {
    const { t } = useTranslation();
    const totalPages = Math.ceil(totalItems / pageSize);
    return (
        <Box bg="white" rounded="md" shadow="md" p={4}>
            <Table variant="simple" >
                <Thead>
                    <Tr>
                        <Th>{t('name')}</Th>
                        <Th>{t('status')}</Th>
                        <Th>{t('Assistant')}</Th>
                        <Th>{t('lastMofificated')}</Th>
                        <Th />
                    </Tr>
                </Thead>
                <Tbody>
                    {data.map((item) => (
                        <Tr key={item.id}>
                            <Td>
                                <HStack spacing={2}>
                                    <Icon
                                        as={IconPdf} 
                                        w={4} 
                                        h={4} 
                                        cursor="pointer" 
                                    />
                                    <Text fontWeight="semibold">{item.name}</Text>
                                </HStack>
                            </Td>
                            <Td>
                                <StatusCustom type={mapStatusToType(item.status)} />
                            </Td>

                            <Td>{item.assistant}</Td>
                         
                            <Td>{item.lastModified}</Td>
                            <Td textAlign="right">
                                <Options item={item} setUpdateData={setUpdateData}/>
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>

            {totalPages > 1 && (
                <HStack justify="flex-end" spacing={2} mt={4}>
                    <IconButton
                        aria-label={t('PreviousPage')}
                        icon={<ChevronLeftIcon />}
                        onClick={() => onPageChange(currentPage - 1)}
                        isDisabled={currentPage === 1}
                        variant="outline"
                        size="sm"
                    />
                    <Text>
                        {currentPage} / {totalPages}
                    </Text>
                    <IconButton
                        aria-label={t('NextPage')}
                        icon={<ChevronRightIcon />}
                        onClick={() => onPageChange(currentPage + 1)}
                        isDisabled={currentPage === totalPages}
                        variant="outline"
                        size="sm"
                    />
                </HStack>
            )}
           
        </Box>
    );
};

export default FilesTable;
