import { Route, Routes } from "react-router-dom";
import SignInCallback from "../pages/SignInCallback.tsx";
import KnoweledgeBase from '../pages/KnoweledgeBases/index.tsx';
import Welcome from '../pages/Welcome/index.tsx';
import Chat from '../pages/Chat/index.tsx';
import Conversations from '../pages/Conversations/index.tsx';
import Files from '../pages/Files/index.tsx';
import Dashboard from '../pages/Dashboard/index.tsx';
import FormKnoweledgeBase from '../pages/FormKnoweledgeBase/index.tsx';
import Layout from "../ui/Layout";
import ProtectedRoute from "./ProtectedRoute.tsx";
import CreateFormKB from "../pages/CreateFormKB/index.tsx";


function AppRoutes() {

    return (
        <>
            <Routes>
                <Route path="/sign-in-callback" element={<SignInCallback />} />
                <Route element={<ProtectedRoute />}> 
                    <Route element={<Layout />}>
                        <Route path="/welcome" Component={Welcome} />
                        <Route path="/" Component={KnoweledgeBase} />
                        <Route path="/chat" Component={Chat} />
                        <Route path="/conversations" Component={Conversations} />
                        <Route path="/files" Component={Files} />
                        <Route path="/dashboard" Component={Dashboard} />
                        <Route path="/edit-knoweledge-base/:assistantId" Component={FormKnoweledgeBase} />
                        <Route path="/create-knoweledge-base" Component={CreateFormKB} />
                    </Route>
                </Route>
            </Routes>
        </>
    );
}

export default AppRoutes;
