import React from 'react';

interface IconProps {
  width?: number;
  height?: number;
}

const DangerZoneIcon: React.FC<IconProps> = ({ width = 16, height = 16 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 16" fill="none">
            <path d="M11.9998 1.33398H6.6665L2.67984 5.33398L2.6665 13.334C2.6665 14.0673 3.2665 14.6673 3.99984 14.6673H11.9998C12.7332 14.6673 13.3332 14.0673 13.3332 13.334V2.66732C13.3332 1.93398 12.7332 1.33398 11.9998 1.33398ZM11.9998 13.334H3.99984V5.88732L7.21984 2.66732H11.9998V13.334Z" fill="#DB071E"/>
            <path d="M7.3335 10.0007H8.66683V11.334H7.3335V10.0007ZM7.3335 5.33398H8.66683V8.66732H7.3335V5.33398Z" fill="#DB071E"/>
        </svg>
    );
};

export default DangerZoneIcon;
