import axios, { AxiosResponse } from 'axios';
import type { AxiosError } from 'axios';

interface CreateFilesBody {
    file: File;
    metadata: { name: string };
}

interface ResponseCreateFile {
 id: number;
 name: string;
 description: string;
}

export const createFile = async (
    token: string,
    signOut: () => Promise<void>,
    showToast: (message: string, status?: 'success' | 'error') => void,
    fileData: CreateFilesBody
): Promise<ResponseCreateFile | undefined> => {
    const formData = new FormData();
    formData.append('file', fileData.file);
    formData.append('metadata', JSON.stringify(fileData.metadata));
    console.log("formData", fileData);
    try {
        const response: AxiosResponse = await axios.post( `${import.meta.env.VITE_API_ENDPOINT}/api/files`, formData, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
            if(error.response?.data?.detail){
                showToast(error.response.data.detail);
            }
            const axiosError = error as AxiosError;
            if (axiosError.response) {
                if (axiosError.response.status === 401) {
                    await signOut();
                    showToast('Sesión expirada. Por favor, inicia sesión nuevamente.', 'error');
                } else if(axiosError.response.status === 400){
                    const errorDetail = (axiosError.response?.data as { detail: string })?.detail;
                    if (errorDetail) {
                        showToast(errorDetail);
                    }
                } else {
                    console.error('Error uploading file:', fileData.metadata.name, error);
                    showToast('Hubo un error al crear el archivo.');
                }
           
            } else {
                showToast('Ocurrió un error inesperado. Por favor, intenta nuevamente.', 'error');
            }
            throw error;
        }
    };
}


export const uploadFilesSequentially = async (
    token: string,
    signOut: () => Promise<void>,
    showToast: (message: string, status?: 'success' | 'error') => void,
    files: CreateFilesBody[],
): Promise<number[]> => {
    const ids: number[] = [];
  
    for (const fileData of files) {
      
        console.log('aca le mando los archivos cada uno', fileData);
        const response = await createFile(token, signOut, showToast, fileData);
        if (response) {
            ids.push(response.id);
        }
    }
  
    return ids;
};