import axios, { AxiosError } from 'axios';
import { authStore } from '../store/authStore';

type SignOutFn = ((url: string) => Promise<void>) | undefined;

const createApiClient = (token: string, signOut: SignOutFn, showToast: (message: string) => void) => {
    const { resetAuthState } = authStore.getState();
    
    const client = axios.create({
        baseURL: `${import.meta.env.VITE_API_ENDPOINT}/api`,
        timeout: 4000,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }
    });

    let isToastVisible = false;

    client.interceptors.response.use(
        response => response,
        async (error: AxiosError) => {
            if (error.response?.status === 401 || error.response?.status === 400 || error.response?.status === 403) {
                resetAuthState();
                if (signOut) {
                    showToast('Vuelve a loguearte tu token expiro.');
                    await signOut(window.location.origin + "/")
                } else {
                    showToast('Hubo un error de autenticación');
                }
            }  else {
                if (!isToastVisible) {
                    showToast("Hubo un error. Por favor, inténtelo de nuevo en unos minutos.");
                    isToastVisible = true;
                    setTimeout(() => {
                        isToastVisible = false;
                    }, 5000);
                }
            }
            return Promise.reject(error);
        }
    );

    return client;
};

export default createApiClient;
