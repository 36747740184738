
import {
    Box,
    Text,
    HStack,
    Icon,
} from '@chakra-ui/react';
import { Button } from '@canaia/ui-kit';
import DangerZoneIcon from '../../../icons/dangerzone';
import { useTranslation } from 'react-i18next';

interface DangerZoneBoxProps {
    handleDeleteClick: (item: number) => void;
    item: number;
}
export default function DangerZoneBox({handleDeleteClick, item} :DangerZoneBoxProps) {
    const { t } = useTranslation();
    
    return (
        <Box
            border="1px solid"
            borderColor="elevate.300"
            borderRadius="8px"
            p={4}
            w="100%" 
            mx="auto"
        >
            <HStack spacing={2} align="center" mb={2}>
                <Icon as={DangerZoneIcon} color="elevate.500" />
                <Text fontWeight="bold" color="elevate.600">
                    {t('dangerZone')}
                </Text>
            </HStack>

            <Text mt={2} textAlign="start">
                {t('ThisActionCannotBeUndonePleaseMakeSureToSave')}
            </Text>
            <Box display="flex" justifyContent="center" mt={6}>

                <Button 
                    color="elevate.500"
                    type="outline"
                    size="md"
                    text={t('delete')}
                    onClick={() =>handleDeleteClick(item)}
                />
            </Box>
        </Box>
    );
}
