import { useEffect, useState } from 'react';
import {
    Box,
    Heading,
    Flex,
    InputGroup,
    Input,
    InputLeftElement,
    useDisclosure 
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { Button, Spinner } from '@canaia/ui-kit';
import { getFiles } from '../../api/Calls/files/getFiles';
import { useGetTokenWithOrganization } from '../../hooks/useGetTokenWithOrganization';
import { useLogto } from '@logto/react';
import  useToastError  from "../../hooks/useToastError";
import FilesTable from '../../ui/components/TableFiles';
import { FilesData} from '../../ui/components/TableFiles/files.types';
import  ModalUploaderFiles  from '../../ui/components/ModalUploaderFiles';
import { useTranslation } from 'react-i18next';

function KnowledgeBasesPage() {
    const { t } = useTranslation();
    const { signOut } = useLogto();
    const showError = useToastError();
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [files, setFiles] = useState<FilesData[]>([]); 
    const { fetchUpdatedToken } = useGetTokenWithOrganization();
    const [isLoading, setIsLoading] = useState(true);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [updateData, setUpdateData] = useState(false);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };
    
    useEffect(() => {
        loadFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    useEffect(() => {
        if(updateData){
            loadFiles();
            setUpdateData(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateData]);

    const UploaderSuccess = () => {
        loadFiles();
    }
      
    const loadFiles = async () => {
        try {
            const token = await fetchUpdatedToken();
            if (!token) {
                setIsLoading(false);
                return;
            }
            const params = {page: currentPage, size: pageSize};
            const data = await getFiles(token, signOut, showError, params);
            setTotalItems(data.total);
            setTotalPages(data.pages)
            setFiles(data.items);
        } catch {
            showError(t('HaveAErrorPliseTryAgainInParMinutes'));
        } finally {
            setIsLoading(false);
        }
    };
      
    return (
        <Box
            minH="100vh"
            p={4}
        >
            {isLoading && <Spinner />}
            <Heading mb="40px" textAlign="left" fontSize="36px">{t('Files')}</Heading>
            <Flex
                justify="flex-end"
                align="center"
                mb={4}
            >
                <Button color="elevate.500" text={t('UploaderYourFiles')} onClick={onOpen}/>
                <ModalUploaderFiles
                    isOpen={isOpen}
                    onClose={onClose}
                    UploaderSuccess={UploaderSuccess}
                    setIsLoading={setIsLoading}
                />
                <InputGroup w="300px" ml={4}>
                    <InputLeftElement pointerEvents="none">
                        <SearchIcon color="gray.400" />
                    </InputLeftElement>
                    <Input
                        placeholder={t('search')+'...'}
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                        bg={"white"}
                    />
                </InputGroup>
            </Flex>
            {files?.length === 0 
                ?  <Box>{t('Nodata')}</Box> 
                :
                <FilesTable
                    data={files ? files : []}              
                    currentPage={currentPage}        
                    pageSize={pageSize}             
                    totalItems={totalItems} 
                    onPageChange={handlePageChange}
                    totalPages={totalPages}
                    setUpdateData={setUpdateData}
                />
            }
        </Box>
    );
}

export default KnowledgeBasesPage;
