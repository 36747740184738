import React from "react";

interface IconProps {
  color?: string; 
}

const NegativeIcon: React.FC<IconProps> = ({ color = "#999999" }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
        >
            <path
                d="M7.05013 12H2.25C1.42157 12 0.75 11.3285 0.75 10.5V8.92178C0.75 8.7258 0.788385 8.53177 0.86298 8.35065L3.18376 2.71444C3.29947 2.43341 3.57335 2.25 3.87727 2.25H16.5C16.9142 2.25 17.25 2.58579 17.25 3V10.5C17.25 10.9142 16.9142 11.25 16.5 11.25H13.8886C13.6449 11.25 13.4165 11.3684 13.2759 11.5675L9.18585 17.3617C9.07897 17.5131 8.87752 17.5638 8.71177 17.4809L7.35122 16.8006C6.56248 16.4063 6.15515 15.5156 6.3727 14.661L7.05013 12ZM12.75 10.0594V3.75H4.37954L2.25 8.92178V10.5H7.05013C8.02868 10.5 8.74515 11.4218 8.5038 12.3701L7.82632 15.0311C7.78282 15.202 7.86427 15.3801 8.02208 15.459L8.5179 15.7069L12.0505 10.7025C12.2379 10.4369 12.4775 10.2193 12.75 10.0594ZM14.25 9.75H15.75V3.75H14.25V9.75Z"
                fill={color}
            />
        </svg>
    );
};

export default NegativeIcon;
