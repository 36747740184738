import React from 'react';
import { Box } from '@chakra-ui/react';
import { keyframes } from '@emotion/react';


const dotFlashing = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
`;

const ThreeDotsSpinner: React.FC = () => {
    return (
        <Box display="flex" justifyContent="start" alignItems="center" mt="10px">
            {Array(3)
                .fill('')
                .map((_, i) => (
                    <Box
                        key={i}
                        width="7px"
                        height="7px"
                        mx="2px"
                        bg="gray.500"
                        borderRadius="50%"
                        animation={`${dotFlashing} 1.5s ${i * 0.2}s infinite`}
                    />
                ))}
        </Box>
    );
};

export default ThreeDotsSpinner;
