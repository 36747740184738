import { 
    Menu,
    MenuButton,
    MenuList, 
    MenuItem , 
    IconButton, 
    AlertDialog,
    AlertDialogOverlay, 
    AlertDialogContent, 
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogCloseButton,
    Button
} from "@chakra-ui/react"
import { BiDotsVerticalRounded } from 'react-icons/bi';
import  RobotBigIcon  from '../../../icons/robot';
import { FaPencilAlt} from 'react-icons/fa';
import { RiDeleteBinLine } from "react-icons/ri";
import {KnowledgeBaseItem } from '../../../pages/KnoweledgeBases/KnowledgeBaseItem.types'
import {useNavigate} from "react-router-dom";
import { deleteAssistant } from "../../../api/Calls/KnowledgeBase/delete";
import { useGetTokenWithOrganization } from "../../../hooks/useGetTokenWithOrganization";
import { useLogto } from "@logto/react";
import useToastError from "../../../hooks/useToastError";
import { useState, useRef } from "react";
import { Button as ButtonCustom } from "@canaia/ui-kit";
import useToastSuccess from "../../../hooks/useToastSuccess";
import { useTranslation } from "react-i18next";

const MenuDropDawn = ({ item, setUpdateData }: { item: KnowledgeBaseItem, setUpdateData: (value: boolean) => void }) => {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const {fetchUpdatedToken } = useGetTokenWithOrganization();
    const {signOut} = useLogto();
    const showError = useToastError();
    const showToastSuccess = useToastSuccess();
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const cancelRef = useRef<HTMLButtonElement>(null);
    const [itemToDelete, setItemToDelete] = useState<KnowledgeBaseItem | null>(null);

    const handleEdit = (item: KnowledgeBaseItem) => {
        navigate(`/edit-knoweledge-base/${item.id}`)
    };

    const handleTry = () => {
        navigate('/chat')
    };

    const handleDelete = async (item: KnowledgeBaseItem) => {
        if (!itemToDelete) return;

        const token = await fetchUpdatedToken();
        if (token) {
            await deleteAssistant(token, signOut, showError, item.id);
        } else {
            setItemToDelete(null);
            setIsDeleteModalOpen(false);
        }
        showToastSuccess(`${item.name} ${t('hasBeenDeletedSuccessfully')}`)
        setUpdateData(true);
        setItemToDelete(null);
        setIsDeleteModalOpen(false);
    };

    const handleDeleteClick = (item: KnowledgeBaseItem) => {
        setItemToDelete(item);
        setIsDeleteModalOpen(true);
    };

    const handleDeleteCancel = () => {
        setItemToDelete(null);
        setIsDeleteModalOpen(false);
    };

    return (
        <>
            <Menu>
                <MenuButton
                    as={IconButton}
                    aria-label="Opciones"
                    icon={<BiDotsVerticalRounded />}
                    variant="ghost"
                    size="sm"
                />
                <MenuList>
                    <MenuItem icon={<FaPencilAlt size={14}/>} onClick={() => handleEdit(item)}>
                        {t('edit')}
                    </MenuItem>
                    <MenuItem icon={<RobotBigIcon  width={16} height={16}/>} onClick={() => handleTry()}>
                        {t('try')}
                    </MenuItem>
                    <MenuItem color="red" icon={<RiDeleteBinLine color="red" size={16} />} onClick={() => handleDeleteClick(item)}>
                        {t('delete')}
                    </MenuItem>
                </MenuList>
            </Menu>

            <AlertDialog
                isOpen={isDeleteModalOpen}
                leastDestructiveRef={cancelRef}
                onClose={handleDeleteCancel}
                isCentered
            >
                <AlertDialogOverlay >
                    <AlertDialogContent  bg="white" p={5} borderRadius="10px" >
                        <AlertDialogCloseButton />
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            {t('DeleteKnowledgeBase')}
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            {t('AreyouSureYouCanTUndoThisActionAfterwards')}
                        </AlertDialogBody>

                        <AlertDialogFooter justifyContent={'space-around'} mt="10px" width="100%">
                            <Button
                                colorScheme="red"
                                variant="outline"
                                ref={cancelRef}
                                onClick={handleDeleteCancel}
                            >
                                {t('Cancel')}
                            </Button>
                            <ButtonCustom
                                color="danger.300"
                                onClick={() => handleDelete(itemToDelete!)}
                                text={t('Delete')}
                            />
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>

        </>
      
    )
}

export default MenuDropDawn;