import createApiClient from "../../ApiClients";

export const getAssistant = async (
    token: string,
    signOut: () => Promise<void>,
    showToastError: (message: string) => void,
    assistantId: string | number
) => {
    const apiClient = createApiClient(token, signOut, showToastError);
    const response = await apiClient.get(`/assistants/${assistantId}`);
    return response.data;
};
