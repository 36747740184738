import { useLogto } from "@logto/react";
import { useEffect, useCallback } from "react";
import { authStore } from "../store/authStore";

export const useGetTokenWithOrganization = () => {
    const { isAuthenticated, getAccessToken, signOut } = useLogto();
    const { accessToken, setAccessToken } = authStore.getState();
    const { organizationId } = authStore.getState();
 
    const fetchUpdatedToken = useCallback(async () => {
        if (isAuthenticated) {
            const token = await getAccessToken(import.meta.env.VITE_LOGTO_API_ID, organizationId);
            if(token){
                setAccessToken(token || null);
                return token; 
            } else {
                signOut(window.location.origin + "/")
            }
        }
        return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, getAccessToken]);

    useEffect(() => {
        fetchUpdatedToken();
    }, [fetchUpdatedToken]);

    return { accessToken, fetchUpdatedToken };
};
