import createApiClient from '../../ApiClients';

interface GetConversationsParams {
  page?: number;  
  size?: number;
  from?: string;  
  to?: string;
}

export const getConversations = async (
    token: string,
    signOut: () => Promise<void>,
    showToast: (message: string) => void,
    { page = 1, size = 50, from, to }: GetConversationsParams = {}
) => {
    const apiClient = createApiClient(token, signOut, showToast);
    const response = await apiClient.get('/conversations', {
        params: {
            page,
            size,
            ...(from && { from }),
            ...(to && { to }),
        },
    });
    return response.data;
};
