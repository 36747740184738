import React from 'react';

interface IconProps {
  width?: number;
  height?: number;
  color?: string;
}

const FilesIcon: React.FC<IconProps> = ({ width = 24, height = 24, color="#666666" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
            <g clipPath="url(#clip0_4527_8901)">
                <path d="M22.5 15H12V16.5H22.5V15Z" fill={color}/>
                <path d="M22.5 18H12V19.5H22.5V18Z" fill={color}/>
                <path d="M17.25 21H12V22.5H17.25V21Z" fill={color}/>
                <path d="M10.5 19.5H3V4.5H8.3775L10.9425 7.0575L11.3775 7.5H21V13.5H22.5V7.5C22.5 7.10218 22.342 6.72064 22.0607 6.43934C21.7794 6.15804 21.3978 6 21 6H12L9.4425 3.4425C9.30296 3.30212 9.13701 3.19075 8.95423 3.11481C8.77144 3.03886 8.57543 2.99984 8.3775 3H3C2.60218 3 2.22064 3.15804 1.93934 3.43934C1.65804 3.72064 1.5 4.10218 1.5 4.5V19.5C1.5 19.8978 1.65804 20.2794 1.93934 20.5607C2.22064 20.842 2.60218 21 3 21H10.5V19.5Z" fill={color}/>
            </g>
            <defs>
                <clipPath id="clip0_4527_8901">
                    <rect width={width} height={height} fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default FilesIcon;


