import createApiClient from '../../ApiClients';

interface GetConversationMessagesParams {
  page?: number;
  size?: number;
}

export const getConversationMessages = async (
    token: string,
    conversationId: number,
    signOut: () => Promise<void>,
    showToast: (message: string) => void,
    { page = 1, size = 50 }: GetConversationMessagesParams = {}
) => {
    const apiClient = createApiClient(token, signOut, showToast);

    const response = await apiClient.get(`/conversations/${conversationId}/messages`, {
        params: {
            page,
            size,
        },
    });

    return response.data;
};
