import React from 'react';

interface IconProps {
  width?: number;
  height?: number;
  color?: string;
}

const ChatIcon: React.FC<IconProps> = ({ width = 24, height = 24, color="#666666" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
            <g clipPath="url(#clip0_4527_8879)">
                <path d="M21 1.5H13.5C12.6724 1.5 12 2.17237 12 3V7.5C12 8.32762 12.6724 9 13.5 9H15.3172L16.6157 11.25L17.915 10.5L16.1828 7.5H13.5V3H21V7.5H18.75V9H21C21.8276 9 22.5 8.32762 22.5 7.5V3C22.5 2.17237 21.8276 1.5 21 1.5Z" fill={color}/>
                <path d="M11.0181 23.25L9.71875 22.5L12.3174 18H16.5002C17.3286 18 18.0002 17.3285 18.0002 16.5V12.75H19.5002V16.5C19.5002 18.1568 18.157 19.5 16.5002 19.5H13.183L11.0181 23.25Z" fill={color}/>
                <path d="M7.5 13.5C7.91421 13.5 8.25 13.1642 8.25 12.75C8.25 12.3358 7.91421 12 7.5 12C7.08579 12 6.75 12.3358 6.75 12.75C6.75 13.1642 7.08579 13.5 7.5 13.5Z" fill={color}/>
                <path d="M10.5 13.5C10.9142 13.5 11.25 13.1642 11.25 12.75C11.25 12.3358 10.9142 12 10.5 12C10.0858 12 9.75 12.3358 9.75 12.75C9.75 13.1642 10.0858 13.5 10.5 13.5Z" fill={color}/>
                <path d="M13.5 13.5C13.9142 13.5 14.25 13.1642 14.25 12.75C14.25 12.3358 13.9142 12 13.5 12C13.0858 12 12.75 12.3358 12.75 12.75C12.75 13.1642 13.0858 13.5 13.5 13.5Z" fill={color}/>
                <path d="M9 19.5H4.5C2.84317 19.5 1.5 18.1568 1.5 16.5V9C1.5 7.34317 2.84317 6 4.5 6H10.5V7.5H4.5C3.67155 7.5 3 8.17155 3 9V16.5C3 17.3285 3.67155 18 4.5 18H9V19.5Z" fill={color}/>
            </g>
            <defs>
                <clipPath id="clip0_4527_8879">
                    <rect width={width} height={height} fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default ChatIcon;
