import React from 'react';

interface IconProps {
  width?: number;
  height?: number;
  color?: string;
}

const DashboardIcon: React.FC<IconProps> = ({ width = 24, height = 24, color="#666666" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
            <g clipPath="url(#clip0_4527_8910)">
                <path d="M12 12.75V18.75H4.5V12.75H12ZM12 11.25H4.5C4.10218 11.25 3.72064 11.408 3.43934 11.6893C3.15804 11.9706 3 12.3522 3 12.75V18.75C3 19.1478 3.15804 19.5294 3.43934 19.8107C3.72064 20.092 4.10218 20.25 4.5 20.25H12C12.3978 20.25 12.7794 20.092 13.0607 19.8107C13.342 19.5294 13.5 19.1478 13.5 18.75V12.75C13.5 12.3522 13.342 11.9706 13.0607 11.6893C12.7794 11.408 12.3978 11.25 12 11.25Z" fill={color}/>
                <path d="M20.25 4.5V8.25H12.75V4.5H20.25ZM20.25 3H12.75C12.3522 3 11.9706 3.15804 11.6893 3.43934C11.408 3.72064 11.25 4.10218 11.25 4.5V8.25C11.25 8.64782 11.408 9.02936 11.6893 9.31066C11.9706 9.59196 12.3522 9.75 12.75 9.75H20.25C20.6478 9.75 21.0294 9.59196 21.3107 9.31066C21.592 9.02936 21.75 8.64782 21.75 8.25V4.5C21.75 4.10218 21.592 3.72064 21.3107 3.43934C21.0294 3.15804 20.6478 3 20.25 3Z" fill={color}/>
                <path d="M20.25 12.75V16.5H16.5V12.75H20.25ZM20.25 11.25H16.5C16.1022 11.25 15.7206 11.408 15.4393 11.6893C15.158 11.9706 15 12.3522 15 12.75V16.5C15 16.8978 15.158 17.2794 15.4393 17.5607C15.7206 17.842 16.1022 18 16.5 18H20.25C20.6478 18 21.0294 17.842 21.3107 17.5607C21.592 17.2794 21.75 16.8978 21.75 16.5V12.75C21.75 12.3522 21.592 11.9706 21.3107 11.6893C21.0294 11.408 20.6478 11.25 20.25 11.25Z" fill={color}/>
                <path d="M8.25 4.5V8.25H4.5V4.5H8.25ZM8.25 3H4.5C4.10218 3 3.72064 3.15804 3.43934 3.43934C3.15804 3.72064 3 4.10218 3 4.5V8.25C3 8.64782 3.15804 9.02936 3.43934 9.31066C3.72064 9.59196 4.10218 9.75 4.5 9.75H8.25C8.64782 9.75 9.02936 9.59196 9.31066 9.31066C9.59196 9.02936 9.75 8.64782 9.75 8.25V4.5C9.75 4.10218 9.59196 3.72064 9.31066 3.43934C9.02936 3.15804 8.64782 3 8.25 3Z" fill={color}/>
            </g>
            <defs>
                <clipPath id="clip0_4527_8910">
                    <rect width={width} height={height} fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default DashboardIcon;


