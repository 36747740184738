import { Box, Text} from "@chakra-ui/react";
import { StepContentProps } from "./types/createForm.types";
import { StepOne, StepThree, StepTwo, StepFour } from "./StepsComponents";

export const StepContent: React.FC<StepContentProps> = ({
    step, 
    onComplete, 
    onIncomplete,
    initialData,
    updateDataAssistant,
    updateImagePerfil,
    updateFiles,
    filesUploader,
    setFilesDataProgess,
    filesDataProgess,
    imagePerfil,
    handleDeleteFile,
}) => {
    switch (step) {
    case 1:
        return (
            <Box>
                <StepOne 
                    onComplete={onComplete}
                    onIncomplete={onIncomplete}
                    initialData={initialData}
                    updateDataAssistant={updateDataAssistant}
                    updateImagePerfil={updateImagePerfil}
                />
            </Box>
        );
    case 2:
        return (
            <Box>
                <StepTwo
                    onComplete={onComplete}
                    onIncomplete={onIncomplete}
                    updateFiles={updateFiles}
                    setFilesDataProgess={setFilesDataProgess}
                    filesDataProgess={filesDataProgess}
                />
            </Box>
        );
    case 3:
        return (
            <Box>
                <StepThree
                    onComplete={onComplete} 
                    onIncomplete={onIncomplete}
                    initialData={initialData} 
                    updateDataAssistant={updateDataAssistant}
                />
            </Box>
        );
    case 4:
        return (
            <Box>
                <StepFour 
                    step={step}
                    updateDataAssistant={updateDataAssistant}
                    initialData={initialData}
                    imagePerfil={imagePerfil}
                    filesUploader={filesUploader}
                    updateImagePerfil={updateImagePerfil}
                    handleDeleteFile={handleDeleteFile}
                    updateFiles={updateFiles}
                    onComplete={onComplete}
                    onIncomplete={onIncomplete}
                />
            </Box>
        );
    default:
        return <Text>Unknown Step</Text>;
    }
};