
interface CreateMessageParams {
    conversation_id: number;
    content: string;
  }

export const createMessage = async (
    token: string,
    signOut: () => Promise<void>,
    showToast: (message: string) => void,
    { conversation_id, content }: CreateMessageParams
): Promise<Response | undefined> => {
    try {
  
        const response = await fetch(`${import.meta.env.VITE_API_ENDPOINT}/api/conversations/${conversation_id}/messages`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ content }),
        });
  
        if (response.status === 401 || response.status === 403) {
            await signOut();
            showToast('Sesión expirada, inicie sesión de nuevo.');
        }
  
        if (!response.ok) {
            showToast('Error creando el mensaje en el servidor.');
            throw new Error(`Error status: ${response.status}`);
        }
  
        return response;
    } catch  {
        showToast('An error occurred while creating the message.');
    }
};
  