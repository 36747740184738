import { Box, Flex, VStack, Text, Stack } from "@chakra-ui/react";
import {Input, PhotoUpload, MultiInput} from "@canaia/ui-kit";
import { useTranslation } from "react-i18next";
import { StepFourProps } from "../types/steps.types.tsx";
import FileTableStepFour  from "./tableFiles.tsx";

const StepFour = ({
    initialData,
    imagePerfil,
    filesUploader,
}: StepFourProps) => {
    const { t } = useTranslation();
    const examplesFilter = initialData.examples.filter(
        (example: string | undefined) =>
            typeof example === 'string' && example.trim().length > 0
    )
    return (
        <Box  
            minHeight="100vh"
            maxW="900px" 
            margin="auto" >
            <VStack spacing={8} align="stretch">

                <Flex justify="space-between" align="center">
                    <Text fontSize="24px" fontWeight="700">Knowledge base summary</Text>
                </Flex>
                <Flex justify="space-between" align="center">
                 
                    <Stack spacing={4} mb={4} mr={8} flex="1" mt={2}>
                        <Text textAlign="left" fontSize="16px" fontWeight="700"> {t('Image')} </Text>
                        <PhotoUpload
                            description={(t('UploadImage'))}
                            iconColor="elevate.500"
                            initialImage={imagePerfil}
                            isReadOnly={true}
                        />
                    </Stack>
                    <Stack spacing={4} mb={4} flex="4">
                        <Input
                            label={(t('name'))}
                            isRequired
                            info
                            infoText={(t('enterTheNameOfYourDatabase'))}
                            product="elevate"
                            value={initialData.name}
                            isReadOnly={true}
                            isDisabled={true}
                        />
                        <Input
                            label={(t('description'))}
                            info
                            infoText={(t('enterADescriptionOfThisDatabase'))}
                            product="elevate"
                            value={initialData.description}
                            textArea={true}
                            isReadOnly={true}
                            isDisabled={true}
                        />
                    </Stack>
                </Flex>

                <VStack spacing={4} align="stretch">
                    <Input
                        label={(t('conversationStarter'))}
                        info
                        isRequired
                        infoText={(t('addConversationStartersToHelpUsers'))}
                        product="elevate"
                        value={initialData.conversation_starter}
                        textArea={true}
                        dropdown={true}
                        description={(t('addConversationStartersToHelpUsers'))}
                        isReadOnly={true}
                        isDisabled={true}
                    />
                </VStack>
                <Stack spacing={1} mb={2}>
                    <Input
                        label={(t('behavior'))}
                        info
                        infoText={(t('behaviorDefinesHowTheAIKnowledgeBaseActsAndRespondsInConversations'))}
                        product="elevate"
                        value={initialData.behavior}
                        textArea={true}
                        dropdown={true}
                        description={(t('behaviorDefinesHowTheAIKnowledgeBaseActsAndRespondsInConversationsItSetsTheAI'))}
                        isReadOnly={true}
                        isDisabled={true}
                    />
                </Stack>
              
                {examplesFilter && examplesFilter.length !== 0 && (
                    <Stack spacing={2} mb={2} mr={8} flex="2">
                        <MultiInput
                            label={(t('FrequentTopics'))}
                            description={(t('IntroduceFrequentOrCommonTopicsThatUsersOftenAskSoTheyCanUseThemQuickly'))}
                            dropdown={true}
                            info={true}
                            infoText={(t('ThisFieldIsForSpecifyingFrequentQuestions'))}
                            product="elevate"
                            values={initialData.examples}
                            isReadOnly={true}
                            isDisabled={true}
                            placeholders={examplesFilter}
                        />
                    </Stack>
                )}
                    
             
                <Stack spacing={2} mb={4} flex="1">
                    <FileTableStepFour 
                        files={filesUploader}
                    />
                </Stack>
                        
            </VStack>
        </Box>
    
    )
}

export default StepFour;    