import {create} from 'zustand';
import { persist } from 'zustand/middleware';

type State = {
    isLoading: boolean
    userName: string
    accessToken: string | null
    infoUser: {
        email: string
        }
    language: string;
    organizationId: string
}

type Actions = {
    setIsLoading: () => void,
    setUserName: (userName: string | undefined) => void
    setInfoUser: (infoUser: {
        email: string
        }) => void
    setAccessToken: (accessToken: State['accessToken']) => void,
    setLanguage: (language: string) => void
    setOrganizationId: (organizationId: string) => void
    resetAuthState: () => void;

}

export const authStore = create<State & Actions>()(
    persist(
        (set) => ({
            isLoading: false,
            setIsLoading: () => set((state) => ({ isLoading: !state.isLoading })),

            userName: '',
            setUserName: (userName) => set(() => ({ userName: userName || '' })),

            infoUser: { email: '' },
            setInfoUser: (infoUser) => set(() => ({ infoUser: infoUser })),

            accessToken: '',
            setAccessToken: (token) => set(() => ({ accessToken: token })),

            language: '',
            setLanguage: (lang) => set(() => ({ language: lang })),

            organizationId: '',
            setOrganizationId: (organizationId) => set(() => ({ organizationId: organizationId })),

            resetAuthState: () =>
                set(() => ({
                    organizationId: '',
                    userName: '',
                    accessToken: '',
                    firstToken: '',
                    infoUser: { email: '' },
                })),
        }),
        {
            name: 'auth-store',
            partialize: (state) => ({
                language: state.language,
                userName: state.userName,
                organizationId: state.organizationId,
            }),
        }
    )
);

