const mapStatusToType = (status: string): 'progress' | 'ready' | 'failed' | 'inactive' => {
    const lowerStatus = status.toLowerCase();
  
    if (lowerStatus.includes('progress')) {
        return 'progress';
    } else if (lowerStatus.includes('done')) {
        return 'ready';
    } else if (lowerStatus.includes('inactive')) {
        return 'inactive';
    } else if (lowerStatus.includes('fail')) {
        return 'failed';
    } 

    return 'inactive'; 
}
  
export default mapStatusToType