// Función de ayuda para retornar un color dependiendo del estado
export function getStatusColor(status: string): string {
    switch (status) {
    case 'Deploying':
        return 'blue.500';
    case 'Completed':
        return 'green.500';
    case 'Failed':
        return 'red.500';
    case 'Inactive':
    default:
        return 'gray.500';
    }
}