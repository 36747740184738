import createApiClient from '../../ApiClients';

interface UpdateMessageParams {
  messageId: number;
  feedback: 'positive' | 'negative' | null;
}

export const updateMessage = async (
    token: string,
    signOut: () => Promise<void>,
    showToast: (message: string) => void,
    { messageId, feedback }: UpdateMessageParams
) => {
    const apiClient = createApiClient(token, signOut, showToast);
    const response = await apiClient.patch(`/messages/${messageId}`, {
        feedback,
    });

    return response.data;
};
