import React, { useEffect, useRef, useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    FormControl,
    FormLabel,
    Input,
    Textarea,
    Select,
    Box,
    Icon,
} from '@chakra-ui/react';
import { editFile } from '../../../api/Calls/files/patchFile';
import { Button } from '@canaia/ui-kit';
import  DangerZone  from "../../../icons/dangerzone"
import { FilesData } from './files.types';
import { useGetTokenWithOrganization } from '../../../hooks/useGetTokenWithOrganization';
import { useLogto } from '@logto/react';
import useToastError from '../../../hooks/useToastError';
import useToastSuccess from '../../../hooks/useToastSuccess';
import getFileUrl from '../../../api/Calls/files/getFileUrl';
import {Spinner} from '@canaia/ui-kit';
import { deleteFile } from '../../../api/Calls/files/delete';
import ModalConfirm from '../ModalConfirm';
import { PropsFilesModal } from './files.types';
import { useTranslation } from 'react-i18next';


const FilesModal: React.FC<PropsFilesModal> = ({ isOpen, onClose, data, setUpdateData }) => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState<FilesData>({
        id: data.id,
        name: data.name,
        status: data.status,
        assistant: data.assistant,
        lastModified: data.lastModified,
        description: data.description || "",
    });
    const { fetchUpdatedToken } = useGetTokenWithOrganization();
    const { signOut }  = useLogto();
    const showError = useToastError();
    const showToastSuccess = useToastSuccess();
    const [isLoadingDowloand, setLoadingDowland] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isOpenConfirm, setIsOpenConfirm] = useState(false);
    const cancelRef = useRef<HTMLButtonElement>(null);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    useEffect(() => {
        if(isDeleteModalOpen) handleDelete(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDeleteModalOpen]);

    const handleDelete = async (item: FilesData) => {
        setLoadingDowland(true)
        setIsOpenConfirm(false);

        const token = await fetchUpdatedToken();
        if (token) {
            await deleteFile(token, signOut, showError, item.id);
        } else {
            setIsDeleteModalOpen(false);
            setLoadingDowland(false)
        }
        showToastSuccess(t('FileDeletedSuccessfully'));
        setUpdateData(true);
        setIsDeleteModalOpen(false);
        setLoadingDowland(false)
    };

    const handleSave = async () => {
        const token = await fetchUpdatedToken();
        const dataSend = {
            name: formData.name,
            description: formData.description || "",
        }
        if (token && formData.id) {
            await editFile(token, signOut, showError, formData.id, dataSend);
            onClose();
            setUpdateData(true)
            showToastSuccess(t('FileUpdatedSuccessfully'));

        }
    };

    const clickDowloand = async () => {
        const token = await fetchUpdatedToken();
      
        setLoadingDowland(true);
        try {
            if(token){
                const fileUrlResponse = await getFileUrl(token, signOut, showError, { file_id: formData.id });
                const url = fileUrlResponse.url;
          
                const link = document.createElement('a');
                link.href = url;
        
                link.download = formData.name || t('File');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                console.log(link)
          
                showToastSuccess(t('DownloadStartedSuccessfully'));
            }
        } finally {
            setLoadingDowland(false);
        }
    };
      
      
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
      
            <ModalOverlay />
            <ModalContent bg={'white'} p="24px" minW="607px">
                {isLoadingDowloand && <Spinner /> }
                <ModalConfirm 
                    isOpen={isOpenConfirm}
                    cancelRef={cancelRef}
                    onCancel={() => setIsOpenConfirm(false)}
                    onConfirm={() => setIsDeleteModalOpen(true)}
                />
             
                <ModalHeader>{t('editFile')}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl isRequired mb={4}>
                        <FormLabel>{t('name')}</FormLabel>
                        <Input         
                            name="name"
                            placeholder="Placeholder"
                            value={formData.name}
                            onChange={handleInputChange} />
                    </FormControl>

                    <FormControl mb={4}>
                        <FormLabel>{t('description')}</FormLabel>
                        <Textarea  
                            name="description"
                            placeholder={t('description')}
                            value={formData.description}
                            onChange={handleInputChange}
                        /> 
                    </FormControl>

                    <FormControl mb={4}>
                        <FormLabel>{t('KnowledgeBases')}</FormLabel>
                        <Select placeholder="Choose an option">
                            <option value="option1">Option 1</option>
                            <option value="option2">Option 2</option>
                        </Select>
                    </FormControl>

                    <Box 
                        textAlign="start"
                        display="flex"
                        justifyContent={'space-between'}
                        mb={4} 
                        width='100%' 
                    >
                        <Button 
                            color="elevate.500"
                            type="outline"
                            text={t('dowland')} 
                            onClick={clickDowloand}
                        />
                        <Button
                            color="elevate.500"
                            mr={3}
                            text="save"
                            onClick={handleSave}
                        />
                    </Box>

                    <Box   
                        border="1px solid"
                        borderColor="danger.400"
                        p={4}
                        borderRadius="md"
                        mt="40px"
                    >
                        <Box 
                            color="danger.400"
                            fontWeight="bold"
                            mb={2} 
                            textAlign="left"
                            display='flex'
                            alignItems='center'
                        >
                            <Box mr="5px"><Icon as={DangerZone} w={6} h={6}/></Box>
                            
                            {t('dangerZone')}
                        </Box>
                        <Box mb={2} textAlign="left">
                            {t('ThisActionCannotBeUndonePleaseMakeSureToSaveTheNecessaryInformation')}
                        </Box>
                        <Button color="elevate.700" text={t('delete')} type="outline" onClick={() => setIsOpenConfirm(true)}/>
                    </Box>
                </ModalBody>

            </ModalContent>
         
       
        </Modal>
    );
};

export default FilesModal;