import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, VStack, Flex } from "@chakra-ui/react";
import { Steps, Button, Spinner } from "@canaia/ui-kit";
import { StepContent } from "./stepContent";
import { initialSteps } from "./mocksSteps";
import { FileData } from "../../ui/components/ModalUploaderFiles/modal.types";
import { FileDataExtended } from "./types/createForm.types";
import { useGetTokenWithOrganization } from "../../hooks/useGetTokenWithOrganization";
import {uploadFilesSequentially} from "../../api/Calls/files/createFile";
import { useLogto } from "@logto/react";
import useToastError from "../../hooks/useToastError";
import useToastSuccess from "../../hooks/useToastSuccess";
import { useTranslation } from "react-i18next";
import { createAssistant } from "../../api/Calls/formKnowledge/postFormKnowledgeBase";
import { useNavigate } from "react-router-dom";
import { updateAssistantImage } from "../../api/Calls/formKnowledge/updateKnowledgeImage";

const StepsWrapper: React.FC = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const [steps, setSteps] = useState(initialSteps);
    const [formDataAssistant, setFormDataAssistant] = useState({
        name: "",
        description: "",
        conversation_starter: "",
        behavior: "",
        examples: [],
    })
    const [filesUploader, setFilesUploader] = useState<FileDataExtended[]>([])
    const [filesDataProgess, setFilesDataProgess] = useState<FileData[]>([])
    const [imagePerfil, setImagePerfil] = useState<File | null>(null)
    const [isLoading, setIsLoading] = useState(false);
    const { fetchUpdatedToken } = useGetTokenWithOrganization();
    const {signOut} = useLogto();
    const showError = useToastError();
    const showToastSuccess = useToastSuccess();
    const { t } = useTranslation();
    const navigate = useNavigate();
    
    const nextStep = () => {
        if (currentStep < 3) {
            setCurrentStep((prev) => prev + 1);
        }
    };

    const prevStep = () => {
        if (currentStep > 0) {
            setCurrentStep((prev) => prev - 1);
        }
    };

    const handleStepClick = (stepIndex: number) => {
        setCurrentStep(stepIndex);
    };
 
    const handleStepComplete = (stepId: number) => {
        setSteps((prevSteps) =>
            prevSteps.map((step) =>
                step.id === stepId ? { ...step, isCompleted: true } : step
            )
        );
    };
    const handleStepInComplete = (stepId: number) => {
        setSteps((prevSteps) =>
            prevSteps.map((step) =>
                step.id === stepId ? { ...step, isCompleted: false } : step
            )
        );
    };

    useEffect(() => {
        setSteps((prevSteps) =>
            prevSteps.map((step, index) => ({
                ...step,
                isActive: index === currentStep,
            }))
        );
    }, [currentStep]);

 
    const updateFilesShowTable = useCallback(
        (files: FileData[]) => {
            const now = new Date().toISOString();
    
            const updatedFiles: FileDataExtended[] = files.map((fileData) => ({
                ...fileData,
                nameAssist: formDataAssistant.name,
                lastModified: now,
            }));
    
            setFilesUploader(updatedFiles);
        },
        [formDataAssistant.name]
    );

    const memoizedInitialData = useMemo(
        () => ({
            name: formDataAssistant.name,
            description: formDataAssistant.description,
            conversation_starter: formDataAssistant.conversation_starter,
            behavior: formDataAssistant.behavior,
            examples: formDataAssistant.examples,
            files: filesUploader,
            image: imagePerfil,
        }),
        [
            formDataAssistant.name,
            formDataAssistant.description,
            formDataAssistant.conversation_starter,
            formDataAssistant.behavior,
            formDataAssistant.examples,
            filesUploader,
            imagePerfil,
        ]
    );

    useEffect(() => {
        const firstThreeCompleted = steps[0]?.isCompleted && steps[1]?.isCompleted && steps[2]?.isCompleted;
        setSteps((prevSteps) => {
            return prevSteps.map((step) => {
                if (step.id === 4 && step.isCompleted !== firstThreeCompleted ) {
                    if(currentStep === 3) {
                        return { ...step, isCompleted: firstThreeCompleted };
                    }
                }
                return step;
            });
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [steps[0]?.isCompleted, steps[1]?.isCompleted, steps[2]?.isCompleted, currentStep]);

    const handleDeleteFile = (index: number) => {
        const updatedFiles = [...filesUploader];
        updatedFiles.splice(index, 1);
        setFilesUploader(updatedFiles);
        setFilesDataProgess((prev) => prev.filter((_, i) => i !== index));
    }

    const handleSendData = async () => {
        console.log("Send Data");
        console.log("formDataAssistant", formDataAssistant);
        console.log("filesUploader", filesUploader);
        console.log("imagePerfil", imagePerfil);
        setIsLoading(true);
        try {
            const token = await fetchUpdatedToken();
            if (!token) {
                showError(t('FailedGetAuthenticationToken'));
                return; // Salimos si no hay token.
            }
      
            // Transformamos el arreglo de filesUploader en el arreglo que espera la API.
            const createFilesBodyArray = filesUploader.map((fileData) => ({
                file: fileData.file,
                metadata: { name: fileData.name },
            }));
      
            // Subimos los archivos y obtenemos el arreglo de IDs.
            const ids = await uploadFilesSequentially(token, signOut, showError, createFilesBodyArray);
            console.log("IDs resultantes:", ids);
      
            // Creamos el objeto del asistente, agregando el campo files_ids.
            const assistantData = {
                ...formDataAssistant,
                files_ids: ids,
                examples:  formDataAssistant.examples.filter(
                    (example: string | undefined) =>
                        typeof example === 'string' && example.trim().length > 0
                )
            };
      
            // Llamamos a la API para crear el asistente.
            const data = await createAssistant(token, signOut, showError, assistantData);
            console.log("Asistente creado:", data);
            // Si existe imagePerfil y se creó el asistente, actualizamos la imagen.
            if (imagePerfil && data && typeof data.id === 'number') {
                await updateAssistantImage(token, data.id, imagePerfil, signOut, showError);
                console.log("Imagen actualizada correctamente");
            }
      
            showToastSuccess(t('fileSploadedSuccessfully'));
            // Limpiamos los estados de los archivos.
            setFilesUploader([]);
            setFilesDataProgess([]);
            setImagePerfil(null);
            setFormDataAssistant({
                name: "",
                description: "",
                conversation_starter: "",
                behavior: "",
                examples: [],
            });
            navigate('/');
            
        } catch {
            console.log('aca cierro loading')
            setIsLoading(false);
            // Acá podrías notificar el error si lo considerás necesario.
        }
    }


    return (
        <VStack spacing={8} width="90%" justifyContent="center" alignItems="center" mx="auto">
            {isLoading && <Spinner />}
            <Steps steps={steps} activeStep={currentStep} onStepClick={handleStepClick} color="elevate.500"/>
            <Box w="100%" py="71" maxW="800px"> 
                <StepContent
                    step={currentStep +1}
                    onIncomplete={() =>handleStepInComplete(steps[currentStep].id)}
                    onComplete={() => handleStepComplete(steps[currentStep].id)}
                    updateDataAssistant={(field, value) =>
                        setFormDataAssistant((prev) => ({ ...prev, [field]: value }))
                    }
                    updateFiles={updateFilesShowTable}
                    updateImagePerfil={(image) => setImagePerfil(image)}
                    initialData={memoizedInitialData}
                    filesUploader={filesUploader}
                    setFilesDataProgess={setFilesDataProgess}
                    filesDataProgess={filesDataProgess}
                    imagePerfil={imagePerfil}
                    handleDeleteFile={handleDeleteFile}
                />
            </Box>
            <Flex
                width="100%"
                justifyContent={currentStep === 0 ? "flex-end" :  "space-between"}
                maxW="800px"
            >
                {currentStep !== 0 
                && <Button 
                    onClick={prevStep}
                    mr={4}
                    color="elevate.500"
                    text="Back"
                /> }
                <Button 
                    onClick={currentStep === 3 ? handleSendData : nextStep}
                    mr={4}
                    color="elevate.500"
                    text={currentStep === 3 ? "Enviar" : "Next"}
                    disabled={!steps[currentStep].isCompleted}
                /> 
                
            </Flex>
        </VStack>
    );
};

export default StepsWrapper;