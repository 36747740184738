import React from 'react';

interface IconProps {
  width?: number;
  height?: number;
}

const LogoElevate: React.FC<IconProps> = ({ width = 198, height = 42 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 198 42" fill="none">
            <path d="M17.1072 22.7422C18.6006 22.7422 19.8113 21.5315 19.8113 20.0381C19.8113 18.5447 18.6006 17.334 17.1072 17.334C15.6138 17.334 14.4031 18.5447 14.4031 20.0381C14.4031 21.5315 15.6138 22.7422 17.1072 22.7422Z" fill="#FF6F9A"/>
            <path d="M24.5139 22.7422C26.0074 22.7422 27.218 21.5315 27.218 20.0381C27.218 18.5447 26.0074 17.334 24.5139 17.334C23.0205 17.334 21.8098 18.5447 21.8098 20.0381C21.8098 21.5315 23.0205 22.7422 24.5139 22.7422Z" fill="#FF6F9A"/>
            <path d="M31.9151 22.7422C33.4085 22.7422 34.6192 21.5315 34.6192 20.0381C34.6192 18.5447 33.4085 17.334 31.9151 17.334C30.4216 17.334 29.2109 18.5447 29.2109 20.0381C29.2109 21.5315 30.4216 22.7422 31.9151 22.7422Z" fill="#FF6F9A"/>
            <path d="M34.1022 30.0254C32.9494 29.1635 31.3065 29.2443 30.3153 30.2893C29.378 31.2751 28.3007 32.11 27.0833 32.8049C25.1333 33.9146 22.9948 34.4694 20.6624 34.4694C18.7663 34.4694 16.994 34.087 15.3349 33.322C13.6758 32.5571 12.2268 31.5175 10.9933 30.2085C9.75433 28.8996 8.77395 27.3698 8.04136 25.6191C7.31416 23.8684 6.94786 21.9938 6.94786 19.99C6.94786 17.9861 7.31416 16.1116 8.04136 14.3609C8.76856 12.6156 9.75433 11.0858 10.9933 9.77145C12.2322 8.46248 13.6812 7.42285 15.3349 6.65794C16.994 5.89304 18.7663 5.51058 20.6624 5.51058C22.9948 5.51058 25.1333 6.06541 27.0833 7.17507C28.3007 7.86995 29.378 8.70489 30.3153 9.69065C31.3118 10.7303 32.9494 10.8165 34.1022 9.9546C35.5458 8.87726 35.6697 6.76029 34.3715 5.51058C33.014 4.20162 31.4788 3.09196 29.7551 2.17622C27.0079 0.727203 23.9752 0 20.6624 0C17.9313 0 15.3619 0.527895 12.9594 1.58369C10.5569 2.63948 8.46152 4.06695 6.67853 5.87149C4.89554 7.67603 3.48422 9.78761 2.44459 12.2062C1.40496 14.6302 0.887841 17.2212 0.887841 19.99C0.887841 22.7588 1.40496 25.3551 2.44459 27.7738C2.90246 28.8403 3.44113 29.8369 4.04444 30.7849L0.5 42L12.6362 38.2455C12.7439 38.294 12.8463 38.3532 12.9594 38.4017C15.3619 39.4575 17.9313 39.9854 20.6624 39.9854C23.9752 39.9854 27.0079 39.2582 29.7605 37.7984C31.4788 36.888 33.0194 35.7784 34.3769 34.464C35.6751 33.2143 35.5512 31.0973 34.1075 30.02L34.1022 30.0254Z" fill="#FF6F9A"/>
            <path d="M43.9815 38.3428C43.3405 38.3428 42.7964 38.1219 42.3547 37.6802C41.913 37.2385 41.6921 36.6945 41.6921 36.0534V8.58133C41.6921 7.94032 41.913 7.39626 42.3547 6.95455C42.7964 6.51285 43.3405 6.29199 43.9815 6.29199H62.2962C62.9372 6.29199 63.4813 6.51285 63.923 6.95455C64.3647 7.39626 64.5856 7.94032 64.5856 8.58133C64.5856 9.22235 64.3647 9.76641 63.923 10.2081C63.4813 10.6498 62.9372 10.8707 62.2962 10.8707H46.2708V24.6067H59.2743C59.883 24.6067 60.4163 24.8276 60.8795 25.2693C61.3374 25.711 61.5636 26.2551 61.5636 26.8961C61.5636 27.5371 61.3428 28.065 60.9011 28.4744C60.4594 28.8838 59.9153 29.1262 59.2743 29.1854H46.2708V33.7641H62.2962C62.9372 33.7641 63.4813 33.9849 63.923 34.4267C64.3647 34.8684 64.5856 35.4124 64.5856 36.0534C64.5856 36.6945 64.3647 37.2385 63.923 37.6802C63.4813 38.1219 62.9372 38.3428 62.2962 38.3428H43.9815Z" fill="#333333"/>
            <path d="M69.6218 6.2913C69.6218 5.65028 69.8427 5.10622 70.2844 4.66452C70.7261 4.22281 71.2702 4.00195 71.9112 4.00195C72.5522 4.00195 73.0962 4.22281 73.5379 4.66452C73.9797 5.10622 74.2005 5.65028 74.2005 6.2913V36.0527C74.2005 36.6938 73.9797 37.2378 73.5379 37.6795C73.0962 38.1212 72.5522 38.3421 71.9112 38.3421C71.2702 38.3421 70.7261 38.1212 70.2844 37.6795C69.8427 37.2378 69.6218 36.6938 69.6218 36.0527V6.2913Z" fill="#333333"/>
            <path d="M83.315 29.1848C83.7729 30.5261 84.5916 31.625 85.7659 32.4814C86.9402 33.3379 88.2761 33.7635 89.7736 33.7635C90.7217 33.7635 91.6105 33.5803 92.4508 33.214C93.2911 32.8477 94.013 32.3576 94.627 31.7489C95.0526 31.3233 95.5913 31.1078 96.2323 31.1078C96.8733 31.1078 97.4227 31.3233 97.8806 31.7489C98.3061 32.2067 98.5216 32.7508 98.5216 33.3756C98.5216 34.0005 98.3061 34.5446 97.8806 35.0024C96.814 36.0421 95.5912 36.8554 94.2176 37.4534C92.844 38.0459 91.3627 38.3476 89.7736 38.3476C88.1846 38.3476 86.6978 38.0513 85.3081 37.4534C83.9183 36.8554 82.7063 36.0421 81.6667 35.0024C80.627 33.9628 79.8136 32.7508 79.2157 31.361C78.6178 29.9713 78.3215 28.4845 78.3215 26.8955C78.3215 25.3064 78.6178 23.8197 79.2157 22.4299C79.8136 21.0401 80.627 19.8281 81.6667 18.7885C82.7063 17.7489 83.9183 16.9355 85.3081 16.3375C86.6978 15.7396 88.1846 15.4434 89.7736 15.4434C91.3627 15.4434 92.8494 15.7396 94.2392 16.3375C95.629 16.9301 96.841 17.7489 97.8806 18.7885C98.9202 19.8281 99.7336 21.0401 100.332 22.4299C100.924 23.8197 101.226 25.3064 101.226 26.8955C101.226 27.5042 101.005 28.0374 100.563 28.5007C100.121 28.9586 99.5774 29.1848 98.9364 29.1848H83.3258H83.315ZM96.2269 24.6061C95.769 23.2648 94.9502 22.1659 93.7759 21.3095C92.6016 20.4584 91.2657 20.0274 89.7682 20.0274C88.2707 20.0274 86.9348 20.453 85.7605 21.3095C84.5862 22.1659 83.7513 23.2648 83.2665 24.6061H96.2269Z" fill="#333333"/>
            <path d="M104.695 18.7458C104.42 18.1963 104.382 17.6253 104.582 17.0274C104.781 16.4349 105.169 15.9824 105.751 15.6753C106.3 15.4006 106.871 15.3629 107.469 15.5622C108.062 15.7615 108.514 16.1494 108.821 16.7311L115.921 30.9251L123.015 16.6827C123.322 16.1332 123.769 15.7615 124.367 15.5622C124.965 15.3629 125.552 15.4006 126.129 15.6753C126.678 15.9824 127.05 16.4295 127.249 17.0274C127.448 17.6253 127.411 18.2125 127.136 18.7888L117.978 37.0605C117.828 37.3029 117.661 37.5184 117.477 37.7015C117.327 37.8523 117.117 37.9978 116.858 38.1378C116.599 38.2725 116.287 38.3425 115.921 38.3425C115.554 38.3425 115.231 38.2725 114.957 38.1378C114.682 37.9978 114.466 37.8577 114.315 37.7015C114.132 37.5184 113.982 37.3029 113.858 37.0605L104.7 18.7458H104.695Z" fill="#333333"/>
            <path d="M148.925 36.0104C147.95 36.7429 146.878 37.3085 145.72 37.7072C144.561 38.1058 143.339 38.2997 142.057 38.2997C140.468 38.2997 138.981 38.0034 137.591 37.4055C136.201 36.8076 134.989 35.9942 133.95 34.9546C132.91 33.9149 132.097 32.7029 131.499 31.3132C130.901 29.9234 130.604 28.4367 130.604 26.8476C130.604 25.2585 130.901 23.7718 131.499 22.382C132.091 20.9923 132.91 19.7803 133.95 18.7406C134.989 17.701 136.201 16.8876 137.591 16.2897C138.981 15.6972 140.468 15.3955 142.057 15.3955C143.646 15.3955 145.132 15.6918 146.522 16.2897C147.912 16.8876 149.124 17.701 150.164 18.7406C151.203 19.7803 152.017 20.9923 152.615 22.382C153.212 23.7718 153.509 25.2585 153.509 26.8476V36.0481C153.509 36.6891 153.288 37.2331 152.846 37.6748C152.404 38.1166 151.86 38.3374 151.219 38.3374C150.578 38.3374 150.034 38.1166 149.593 37.6748C149.151 37.2331 148.93 36.6891 148.93 36.0481V36.005L148.925 36.0104ZM142.057 33.721C143.005 33.721 143.893 33.5379 144.734 33.1716C145.574 32.8053 146.296 32.3151 146.91 31.7064C147.524 31.0977 148.009 30.3705 148.375 29.5302C148.741 28.6899 148.925 27.7957 148.925 26.853C148.925 25.9103 148.741 25.0161 148.375 24.1758C148.009 23.3355 147.519 22.6137 146.91 21.9996C146.301 21.3909 145.574 20.9007 144.734 20.5344C143.893 20.1681 142.999 19.985 142.057 19.985C141.114 19.985 140.22 20.1681 139.379 20.5344C138.539 20.9007 137.817 21.3909 137.203 21.9996C136.594 22.6083 136.104 23.3355 135.738 24.1758C135.372 25.0161 135.189 25.9103 135.189 26.853C135.189 27.7957 135.372 28.6952 135.738 29.5302C136.104 30.3705 136.594 31.0923 137.203 31.7064C137.812 32.3151 138.539 32.8053 139.379 33.1716C140.22 33.5379 141.109 33.721 142.057 33.721Z" fill="#333333"/>
            <path d="M158.54 8.58133C158.54 7.94032 158.761 7.39626 159.203 6.95455C159.644 6.51285 160.188 6.29199 160.829 6.29199C161.47 6.29199 162.014 6.51285 162.456 6.95455C162.898 7.39626 163.119 7.94032 163.119 8.58133V15.4494H166.782C167.423 15.4494 167.967 15.6702 168.408 16.1119C168.85 16.5536 169.071 17.0977 169.071 17.7387C169.071 18.3797 168.85 18.9238 168.408 19.3655C167.967 19.8072 167.423 20.028 166.782 20.028H163.119V31.4748C163.119 32.0834 163.34 32.6167 163.781 33.08C164.223 33.5379 164.767 33.7641 165.408 33.7641C166.049 33.7641 166.55 33.5379 167.008 33.08C167.466 32.6221 168.015 32.3905 168.656 32.3905C169.297 32.3905 169.831 32.6167 170.256 33.08C170.714 33.5055 170.946 34.0388 170.946 34.6798C170.946 35.3208 170.714 35.8703 170.256 36.3282C169.647 36.9369 168.92 37.427 168.08 37.7933C167.24 38.1596 166.345 38.3428 165.403 38.3428C164.46 38.3428 163.566 38.1596 162.726 37.7933C161.885 37.427 161.163 36.9369 160.549 36.3282C159.941 35.7195 159.45 34.9923 159.084 34.1519C158.718 33.3116 158.535 32.4228 158.535 31.4748V8.58133H158.54Z" fill="#333333"/>
            <path d="M179.834 29.1848C180.291 30.5261 181.11 31.625 182.284 32.4814C183.459 33.3379 184.795 33.7635 186.292 33.7635C187.24 33.7635 188.129 33.5803 188.969 33.214C189.81 32.8477 190.532 32.3576 191.146 31.7489C191.571 31.3233 192.11 31.1078 192.745 31.1078C193.381 31.1078 193.936 31.3233 194.394 31.7489C194.819 32.2067 195.035 32.7508 195.035 33.3756C195.035 34.0005 194.819 34.5446 194.394 35.0024C193.327 36.0421 192.104 36.8554 190.731 37.4534C189.357 38.0459 187.876 38.3476 186.292 38.3476C184.709 38.3476 183.216 38.0513 181.827 37.4534C180.437 36.8554 179.225 36.0421 178.185 35.0024C177.146 33.9628 176.332 32.7508 175.734 31.361C175.142 29.9713 174.84 28.4845 174.84 26.8955C174.84 25.3064 175.136 23.8197 175.734 22.4299C176.332 21.0401 177.146 19.8281 178.185 18.7885C179.225 17.7489 180.437 16.9355 181.827 16.3375C183.216 15.7396 184.703 15.4434 186.292 15.4434C187.881 15.4434 189.368 15.7396 190.758 16.3375C192.148 16.9301 193.36 17.7489 194.399 18.7885C195.439 19.8281 196.252 21.0401 196.85 22.4299C197.448 23.8197 197.744 25.3064 197.744 26.8955C197.744 27.5042 197.523 28.0374 197.082 28.5007C196.64 28.9586 196.096 29.1848 195.455 29.1848H179.844H179.834ZM192.74 24.6061C192.282 23.2648 191.463 22.1659 190.289 21.3095C189.115 20.4584 187.779 20.0274 186.281 20.0274C184.784 20.0274 183.448 20.453 182.274 21.3095C181.099 22.1659 180.264 23.2648 179.78 24.6061H192.735H192.74Z" fill="#333333"/>
        </svg>
    );
};

export default LogoElevate;
