import React from 'react';
import { Text } from '@chakra-ui/react';
import { format, Locale  } from 'date-fns';
import { es, enUS } from 'date-fns/locale';
import { authStore } from '../../../store/authStore';

interface DateProps {
  isoDate: string;
}

const localeMap: { [key: string]: Locale } = {
    en: enUS,
    es: es,
};

const FormattedDate: React.FC<DateProps> = ({ isoDate }) => {
    const { language } = authStore((state) => state);
    const date = new Date(isoDate);
    const selectedLocale = localeMap[language] || enUS;

    const formattedDate = format(date, 'eee MMM dd yyyy HH:mm', { locale: selectedLocale });

    return <Text>{formattedDate}</Text>;
};

export default FormattedDate;
