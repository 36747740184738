import React from 'react';

interface IconProps {
  width?: number;
  height?: number;
}

const RobotBigIcon: React.FC<IconProps> = ({ width = 85, height = 97 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 85 97" fill="none">
            <path d="M41.5401 12.7406C45.0263 13.3993 48.3875 11.1168 49.0575 7.63061C49.7162 4.14443 47.4337 0.783169 43.9475 0.113187C40.4613 -0.545439 37.1001 1.73704 36.4301 5.22322C35.7715 8.70939 38.0539 12.0707 41.5401 12.7406Z" fill="#3A7684"/>
            <path d="M46.4913 32.1475C60.1521 33.9417 70.7355 45.6607 70.7355 59.8098C70.7355 75.1967 58.2216 87.7106 42.8234 87.7106C27.4252 87.7106 14.9113 75.1967 14.9113 59.8098C14.9113 45.8083 25.279 34.1802 38.7467 32.2043V22.9722C23.5983 24.6301 11.1753 35.452 7.14404 49.7601C8.52943 50.3619 9.75583 51.5088 10.7211 53.1213C12.0724 55.3698 12.8446 58.3676 12.89 61.5699C12.9695 66.8503 11.1299 71.2563 8.30232 73.3116C13.7076 87.0974 27.1413 96.8859 42.8234 96.8859C58.5055 96.8859 71.678 87.279 77.1855 73.7091C76.084 73.0505 75.0847 72.0512 74.2785 70.6998C72.9271 68.4514 72.155 65.4535 72.1095 62.2513C72.0641 59.049 72.7454 55.9943 74.04 53.6323C75.1755 51.5656 76.7086 50.1689 78.4573 49.5784C74.3239 35.2362 61.7532 24.4371 46.4913 22.9268V32.1589V32.1475Z" fill="black"/>
            <path d="M7.4848 54.4156C7.14413 53.8478 6.7694 53.3708 6.3833 52.9961C5.75875 52.3829 5.10012 52.0536 4.52098 52.0763C4.47556 52.0763 4.44149 52.0763 4.39607 52.0763C3.48762 52.1899 2.43155 53.1551 1.6253 54.6427C0.535162 56.6526 -0.0326188 59.2871 0.00144804 62.092C0.0468705 64.8968 0.694141 67.4859 1.8297 69.3823C2.7268 70.8585 3.86236 71.7556 4.79352 71.7215C5.54299 71.6874 6.40602 71.0629 7.16684 69.9614C8.37054 68.2467 9.35848 65.3737 9.3017 61.7059C9.25628 58.901 8.60901 56.3119 7.47344 54.4156H7.4848Z" fill="#010101"/>
            <path d="M83.1701 54.4154C82.273 52.9392 81.1374 52.0421 80.2063 52.0761C80.1609 52.0761 80.1268 52.0761 80.0814 52.0761C79.7861 52.1102 79.4682 52.2465 79.1616 52.4622C78.5143 52.8937 77.8557 53.6546 77.3106 54.6539C76.2205 56.6411 75.6527 59.2756 75.6868 62.0804C75.7322 64.8853 76.3794 67.4743 77.515 69.3707C77.7648 69.7909 78.0374 70.1543 78.3213 70.4722C79.0367 71.2785 79.8088 71.7441 80.4788 71.71C82.3979 71.6078 85.0778 67.6901 84.9984 61.6943C84.9529 58.8895 84.3057 56.3004 83.1701 54.404V54.4154Z" fill="#010101"/>
            <path d="M29.106 49.9527C26.4602 49.8391 24.314 53.6659 24.314 58.5034C24.314 63.3409 26.4602 67.3608 29.1174 67.4744C31.7746 67.5879 33.9095 63.7611 33.9095 58.9236C33.9095 54.0861 31.7632 50.0662 29.106 49.9527ZM30.764 66.1571C29.6057 67.2359 27.9251 66.9066 27.0052 65.4304C26.0854 63.9541 26.2671 61.8761 27.4254 60.7973C28.5837 59.7185 30.2643 60.0478 31.1841 61.524C32.1039 63.0003 31.9222 65.0783 30.764 66.1571Z" fill="black"/>
            <path d="M53.0662 49.3736C50.4203 49.26 48.2741 53.0868 48.2854 57.9243C48.2854 62.7618 50.443 66.7704 53.0889 66.8839C55.7347 66.9975 57.8809 63.1706 57.8696 58.3331C57.8696 53.4956 55.712 49.4871 53.0662 49.3736ZM54.6332 65.7938C53.4636 66.8499 51.783 66.5092 50.8859 65.0102C49.9888 63.5227 50.2045 61.4446 51.3742 60.3885C52.5438 59.3324 54.2244 59.6731 55.1215 61.172C56.0186 62.671 55.8029 64.7377 54.6332 65.7938Z" fill="black"/>
            <path d="M43.9137 31.9313V15.9199H41.3359V31.9541C41.8356 31.9313 42.3352 31.9086 42.8349 31.9086C43.1983 31.9086 43.5616 31.92 43.9137 31.9313Z" fill="black"/>
        </svg>
    );
};

export default RobotBigIcon;
