import { AlertDialog, AlertDialogBody, AlertDialogCloseButton, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button } from "@chakra-ui/react"
import {Button as ButtonCustom} from "@canaia/ui-kit";
import { useTranslation } from "react-i18next";

interface PropsModalConfirm {
    isOpen: boolean;
    onCancel: () => void;
    onConfirm: () => void;
    cancelRef: React.RefObject<HTMLButtonElement>;
    description?: string;
    title?: string;
}

const ModalConfirm = ({isOpen,onConfirm,  onCancel, cancelRef, description, title}: PropsModalConfirm) => {
    const { t } = useTranslation();
    return (
        <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={ onCancel}
            isCentered
        >
            <AlertDialogOverlay >
                <AlertDialogContent  bg="white" p={5} borderRadius="10px" >
                    <AlertDialogCloseButton />
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        {title? title : t('deleteFile')}
                    </AlertDialogHeader>
             
                    <AlertDialogBody>
                        {description? description : t('AreYouSureYouWantToDeleteThisFile')}
                    </AlertDialogBody>
             
                    <AlertDialogFooter justifyContent={'space-around'} mt="10px" width="100%">
                        <Button
                            colorScheme="red"
                            variant="outline"
                            ref={cancelRef}
                            onClick={ onCancel}
                        >
                            {t('Cancel')}
                        </Button>
             
                        <ButtonCustom
                            color="danger.300"
                            onClick={onConfirm}
                            text={t('delete')}
                        />
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}

export default ModalConfirm;