import React from 'react';
import { Box, Text, Flex } from '@chakra-ui/react';
import { Button, AccordionCustom } from '@canaia/ui-kit';
import RobotPink from '../../../icons/robotPink';


type ConversationCategory = {
    title: string;
    content: string[];
  };

interface SidebarProps {
    items: ConversationCategory[];
    colorIcon: string;
    setResetChat: (value: boolean) => void;
}

const Sidebar: React.FC<SidebarProps> = ({ items, colorIcon, setResetChat }) => {
    return (
        <Box bg="elevate.200" w="20%" p="25px" minH="90vh" pt="45px">
           
            <Box mb="6" >
                <Flex
                    alignItems="center"
                    textAlign="center" 
                    justifyContent="center" 
                    marginBottom="20px"
                >
                    <Box mr="10px" pb="10px">
                        <RobotPink />
                    </Box>
                  
                    <Text fontSize="lg" fontWeight="bold">Conversations</Text>
                </Flex>
              
                <Button 
                    color="elevate.500"
                    mt="4"
                    text="Nuevo Chat"
                    width="90%" 
                    size="sm"
                    onClick={() => setResetChat(true)}
                />
            </Box>
            <Box borderBottom="1px solid" borderColor="gray.300" mb="4" />
            <AccordionCustom items={items} colorIcon={colorIcon} />
        </Box>
    );
};

export default Sidebar;
