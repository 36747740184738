import { useEffect, useState } from "react";
import EditKnowledgeBaseForm from "../../ui/components/FormKnowledge/formKnowledge";
import { FileData, CreateFilesBody } from "./formKnowledge.types";
import { createAssistant } from "../../api/Calls/formKnowledge/postFormKnowledgeBase";
import { updateAssistant } from "../../api/Calls/formKnowledge/patchFormKnowledge";
import {useGetTokenWithOrganization} from "../../hooks/useGetTokenWithOrganization";
import { useLogto } from "@logto/react";
import  useToastError  from "../../hooks/useToastError";
import useToastSuccess from "../../hooks/useToastSuccess";
import { Spinner } from "@canaia/ui-kit"
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getAssistant } from "../../api/Calls/KnowledgeBase/getAssistant";
import { useTranslation } from "react-i18next";

const FormKnoweledgeBase = () => {
    const { t } = useTranslation();
    const showError = useToastError();
    const showToastSuccess = useToastSuccess();
    const location = useLocation(); 
    const { assistantId } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const { fetchUpdatedToken } = useGetTokenWithOrganization();
    const [files, setFiles] = useState<FileData[]>([
        {
            name: "doc123.pdf",
            knowledgeBase: "Compensations & Benefits",
            lastModified: "Fri Oct 25 2024 12:21",
        },
    ]);
    const [newFiles, setNewFiles] = useState<CreateFilesBody[]>([]);
    const [formData, setFormData] = useState({
        id: 0,
        name: "",
        description: "",
        conversation_starter: "",
        behavior: "",
        examples: [],
        isStatus: false,
        
    });
    const { signOut } = useLogto();
    
    useEffect(() => {
        if (location.pathname.startsWith("/edit-knoweledge-base") && assistantId) {
            (async () => {
             
                setIsLoading(true);
                const token = await fetchUpdatedToken();
                if (!token) return;
    
                const assistantData = await getAssistant(token, signOut, showError, assistantId);
                    
                setFormData((prev) => ({
                    ...prev,
                    id: assistantData.id,
                    name: assistantData.name || "",
                    description: assistantData.description || "",
                    conversation_starter: assistantData.conversation_starter || "",
                    behavior: assistantData.behavior || "",
                    examples: assistantData.examples || [],
                    // isStatus: si lo devuelves, lo puedes actualizar aquí
                    // isStatus: assistantData.isStatus ?? false,
                }));
    
        
                setIsLoading(false);
                
            })();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assistantId]);

    const handleDeleteFile = (index: number) => {
        const updatedFiles = [...files];
        const deletedFile = updatedFiles.splice(index, 1)[0];
        setFiles(updatedFiles);

        const updatedNewFiles = newFiles.filter(
            (file) => file.metadata.name !== deletedFile.name
        );
        setNewFiles(updatedNewFiles);
    };
    
    const handleSave = async () => {
    
        setIsLoading(true);
        const token = await fetchUpdatedToken();
        if (!token) {
            setIsLoading(false);
            return;
        }
        if (location.pathname === '/create-knoweledge-base') {
            const createDataFirst = {
                name: formData.name,
                description: formData.description
            };
            const responseId = await createAssistant(token, signOut, showError, createDataFirst);
            await updateAssistant(token, signOut, showError, responseId.id, formData);

        } else if (location.pathname.startsWith("/edit-knoweledge-base")) {
            if (assistantId) {
                await updateAssistant(token, signOut, showError, parseInt(assistantId), formData);
            }
        }
        showToastSuccess(t('AssistantCreatedSuccessfully'));
        navigate("/");
     
        setIsLoading(false);
        
    };
      
    const handleInputChange = (field: string, value: string | string[] | File) => {
        setIsLoading(true);

        setFormData((prev) => ({ ...prev, [field]: value }));
        setIsLoading(false);
    };
    const handleChangeStatus = () => {
        setFormData((prev) => ({ ...prev, isStatus: !prev.isStatus }));
    }
    const getFileNew = (file: CreateFilesBody) => {
        setNewFiles((prev) => [...prev, file]);
        setFiles((prev) => [...prev, { name: file.metadata.name, knowledgeBase: formData.name, lastModified: new Date().toString() }]);
        setIsLoading(false);
    } 
    return <>
        {isLoading && <Spinner />}
        <EditKnowledgeBaseForm 
            handleSave={handleSave}
            defaultValues={formData}
            handleInputChange={handleInputChange}
            isLoading={isLoading}
            handleChangeStatus={handleChangeStatus} 
            isStatus={formData.isStatus}
            files={files}
            handleDeleteFile={handleDeleteFile}
            setIsLoading={setIsLoading}
            getFileNew={getFileNew}
        />;
    </>
};
  
export default FormKnoweledgeBase;
