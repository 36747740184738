import React, { useEffect, useRef, useState } from 'react';
import { Box, Grid, GridItem, Text, Spinner, Flex } from '@chakra-ui/react';
import { Button, ChatAssistant, ChatInput, Text as TextCanaia, ChatUser } from '@canaia/ui-kit';
import ImageDefault from '../../../images/imagePerfil.png';
import { useGetTokenWithOrganization } from '../../../hooks/useGetTokenWithOrganization';
import { getAssistants } from '../../../api/Calls/getAssistansTable';
import useToastError from '../../../hooks/useToastError';
import { useLogto } from '@logto/react';
import { KnowledgeBaseItem } from '../../../pages/KnoweledgeBases/KnowledgeBaseItem.types';
import { postConversation } from '../../../api/Calls/conversations/createConversation';
import { createMessage } from '../../../api/Calls/messages/createMessageInConversation';
import { ChatProps, ConversationItem, Conversation } from './chatContent.types';
import ThreeDotsSpinner from '../ThreeDotsSpinner';
import { updateMessage } from '../../../api/Calls/messages/updateFeedbackMsg';
import { getConversationMessages } from '../../../api/Calls/messages/getMessages';

const ChatContent: React.FC<ChatProps> = ({getAsistantSelected,  resetChat, setResetChat}) => {
    const showError = useToastError();
    const { fetchUpdatedToken } = useGetTokenWithOrganization();
    const [isWaitAssitant, setWaitAssistant] = useState(true);
    const [isFetching, setIsFetching] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(4);
    const [totalPages, setTotalPages] = useState(0);
    const [assistants, setAssistants] = useState<KnowledgeBaseItem[]>([]);
    const { signOut } = useLogto();

    // Estado local para la conversación y los mensajes:
    const [isWaitNewConversation, setWaitNewConversation] = useState(false);
    const [newConversation, setNewConversation] = useState<Conversation | null>(null);

    // Este es el array que contiene los mensajes de la conversación.
    // Se inicializa vacío hasta que carguemos algo o creemos la conversación.
    const [messages, setMessages] = useState<ConversationItem[]>([]);
    const messagesContainerRef = useRef<HTMLDivElement>(null);
    
    const [blockInput, setBlockInput] = useState<boolean>(false);

    //Indica si el scroll esta atento a bajar por la nueva conversacion o no.
    const [scrollFollow, setScrollFollow] = useState(true);

    useEffect(() => {
        if (resetChat) {
            setMessages([]);
            setNewConversation(null);
            setCurrentPage(1);
            setResetChat(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetChat]);

    useEffect(() => {
        loadAssistants();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    const handleMoreClick = () => {
        setCurrentPage(currentPage + 1);
    }

    useEffect(() => {
        if (newConversation && newConversation.assistant) {
            const starter = newConversation.assistant.conversation_starter;
            if (starter) {
                const firstMessage: ConversationItem = {
                    id: Date.now(),
                    conversation_id: newConversation.id,
                    content: starter,
                    feedback: null,
                    is_user: false,
                    created_at: new Date().toISOString(),
                    updated_at: new Date().toISOString(),
                };
                setMessages([firstMessage]);
            }
        }
    }, [newConversation]);
      
    //Crea los asistentes de la primera seccion, lo que aparece al principio
    const loadAssistants = async () => {
        if (isFetching) return; // Evitar múltiples llamadas simultáneas
        setIsFetching(true); // Indicar que la función está en ejecución
        setWaitAssistant(currentPage === 1); // Mostrar spinner solo en la primera página
    
 
        const token = await fetchUpdatedToken();
        if (!token) {
            setWaitAssistant(false);
            setIsFetching(false);
            return;
        }
        const params = {page: currentPage, size: pageSize};
        const data = await getAssistants(token, signOut, showError, params);
        setTotalPages(data.pages);
        setAssistants((prevAssistants) => {
            const newItems = data.items.filter(
                (newItem: { id: number; }) => !prevAssistants.some((existingItem) => existingItem.id === newItem.id)
            );
            return [...prevAssistants, ...newItems];
        });
 
        setWaitAssistant(false);
        setIsFetching(false);
        
    };

    const createConversation = async (id: number) => {
        setWaitNewConversation(true);
        const token = await fetchUpdatedToken();
        if (!token) {
            setWaitAssistant(false);
            setIsFetching(false);
            return;
        }
    
        //Crea la conversacion.
        const response = await postConversation(token, signOut, showError, { assistant_id: id });
        //Trae los mensajes de la conversacion para poder obtener el total de mensajes.
        const info = await getConversationMessages(token, response.id, signOut, showError, { page: 1, size: 1 });
        setMessages(info.items || []);
        setWaitNewConversation(false);
        setNewConversation(response)
        setNewConversation(response);
    }

    const selectAssistant = (id: number) => {
        getAsistantSelected(id);
        createConversation(id);
    }

    //Cuando mandas un nuevo mensaje.
    const sendMessage = async (content: string) => {
        if(blockInput) return;
        if (!newConversation) return;

        const token = await fetchUpdatedToken();
        if (!token) return;

        setScrollFollow(true);
      
        setBlockInput(true);
        const userMessage: ConversationItem = {
            id: Date.now(),
            conversation_id: newConversation.id,
            content,
            feedback: '', 
            is_user: true,
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
        };
      
        // Lo metemos al arreglo de mensajes (para que se muestre de inmediato)
        setMessages((prev) => [...prev, userMessage]);
      
        // Creamos el mensaje en la conversacion.
        const response = await createMessage(token, signOut, showError, {
            conversation_id: newConversation.id,
            content: content,
        });
      
        if (!response || !response.body) {
            showError('Ups algo sucedio y nuestro agente no te puede responder.');
            return;
        }
    
        // 3) Creamos un mensaje "vacío" para la respuesta del asistente
        let assistantMessage: ConversationItem = {
            id: Date.now() + 1, 
            conversation_id: newConversation.id,
            content: '',
            feedback: '',
            is_user: false, 
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
            isLoading: true,
        };
      
        // Lo insertamos en el array para que aparezca (aunque es vacío al inicio)
        setMessages((prev) => [...prev, assistantMessage]);
      
        // 4) Leemos el stream con getReader()
        const reader = response.body.getReader();
        const decoder = new TextDecoder('utf-8');
      
        let done = false;
        let partialContent = '';
      
        while (!done) {
            const { value, done: doneReading } = await reader.read();
            done = doneReading;
      
            if (value) {
                // Decodificamos el chunk
                partialContent += decoder.decode(value, { stream: true });
              
                // Actualizamos el contenido del asistente con lo recibido hasta ahora
                assistantMessage = {
                    ...assistantMessage,
                    content: partialContent,
                    isLoading: false,
                };
      
                // Sobrescribimos el último mensaje (que es del asistente)
                setMessages((prev) => {
                    const updated = [...prev];
                    updated[updated.length - 1] = assistantMessage;
                    return updated;
                });
            }
        }

        const info = await getConversationMessages(token, newConversation.id, signOut, showError, { page: 1, size: 1 });
        setBlockInput(false);
        const realMessagesResponse = await getConversationMessages(token, newConversation.id, signOut, showError, {
            page:(info.pages / 2), size: 2
        });
        const lastTwoMessages: ConversationItem[] = realMessagesResponse.items;
   

        // Fusionamos localmente (sólo reemplazamos los que coincidan en contenido) 
        setMessages((prev) => {
            const updated = [...prev];
            const lastServerAssistantMsg = lastTwoMessages.reverse().find(msg => msg.is_user === false);
        
            if (lastServerAssistantMsg) {
                const lastLocalAssistantMsgIndex = updated.slice().reverse().findIndex(msg => msg.is_user === false);
        
                if (lastLocalAssistantMsgIndex !== -1) {
                    const actualIndex = updated.length - 1 - lastLocalAssistantMsgIndex;
                    updated[actualIndex] = {
                        ...updated[actualIndex],
                        id: lastServerAssistantMsg.id,
                        feedback: lastServerAssistantMsg.feedback,
                        created_at: lastServerAssistantMsg.created_at,
                        updated_at: lastServerAssistantMsg.updated_at,
                    };
                }
            }
        
            return updated;
        });
    };
      
    useEffect(() => {
        if ( messagesContainerRef.current && scrollFollow) {
            messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
        }
    }, [messages, scrollFollow]);


    //Mandar el feedback, actualizar el mensaje.
    const updateFeedbackMessage = async (id: number, feedback: 'positive' | 'negative', msg: ConversationItem) => {
        setScrollFollow(false)
        const resultFeedback = msg.feedback === feedback ? null : feedback;

        setMessages((prev) => {
            return prev.map((msg) => {
                if (msg.id === id) {
                    return { ...msg, feedback: resultFeedback };
                }
                return msg;
            });
        });

        const token = await fetchUpdatedToken();
        if (!token) return;

        await updateMessage(
            token,
            signOut,
            showError,
            { messageId: id, feedback: resultFeedback },
        );
    }

    return (
        <Box
            flex="1" 
            bg="#FAECF0"
            justifyContent={newConversation ? "space-between" : "center"}
            alignItems="center"
            display="flex"
            flexDirection="column"
            width="80%"
        >
            {isWaitNewConversation && <Spinner />}
            {newConversation && (
                <Box w="100%" mb="10px" mt="50px" px="60px">
                    <TextCanaia text={newConversation.assistant.name} textAlign="left" />
                    <Flex flexDirection="column" alignItems="center" pt="25px">
                        <Box 
                            key={newConversation.id}
                            ref={messagesContainerRef}
                            display="flex"
                            flexDirection="column"
                            maxW="800px" 
                            minW="800px"
                            justifyContent="start"
                            minH="68vh" 
                            maxH="68vh"
                            overflowY="auto" 
                            px="20px"
                            pb="40px"
                            sx={{
                                "&::-webkit-scrollbar": {
                                    width: "5px", 
                                },
                                "&::-webkit-scrollbar-track": {
                                    background: "#f9f9f9",
                                    borderRadius: "4px",
                                },
                                "&::-webkit-scrollbar-thumb": {
                                    background: "#b3b3b3", 
                                    borderRadius: "4px",
                                },
                                "&::-webkit-scrollbar-thumb:hover": {
                                    background: "#999999", 
                                },
                            }}
                        >
                            {messages.map((msg) => (
                                msg.is_user ? (
                                    <ChatUser message={msg.content} userImage="https://img.freepik.com/fotos-premium/icono-perfil-fondo-blanco_941097-161416.jpg?w=826" />
                                ) : (
                                    msg.isLoading ? (
                                        <ChatAssistant 
                                            key={msg.id}
                                            message={ <ThreeDotsSpinner key={msg.id} />}
                                            color="elevate.500"
                                            withFeedback={!msg.isLoading && msg.feedback}
                                            toastSuccessMsg="Texto copiado"
                                        />
                                      
                                    ) : (
                                        <ChatAssistant 
                                            key={msg.id}
                                            message={msg.content}
                                            color="elevate.500"
                                            withFeedback={!msg.isLoading}
                                            toastSuccessMsg="Texto copiado"
                                            thumbUp={msg.feedback === 'positive'}
                                            thumbDown={msg.feedback === 'negative'}
                                            onThumbUp={() => updateFeedbackMessage(msg.id, 'positive', msg)}
                                            onThumbDown={() => updateFeedbackMessage(msg.id, 'negative', msg)}
                                        />
                                    )
                                )
                            ))}
                        </Box>
                        <Box  
                            maxW="720px" 
                            minW="720px"
                        >
                            <ChatInput placeholder="Mandar mensaje" onSend={sendMessage} disabled={blockInput}/>
                        </Box>
                    </Flex>
                </Box>
            )}
            {!newConversation && !isWaitNewConversation && (
                <>
                    <Text 
                        fontSize="2xl"
                        fontWeight="bold"
                        textAlign="center"
                        mb="6"
                    >¿En qué te puedo ayudarte?</Text>
                    {isWaitAssitant? <Spinner mt="30px" /> : (
                        <Grid
                            templateColumns="repeat(2, 1fr)" 
                            gap="4"
                            justifyItems="center"
                        >
                            {assistants.map((assist, index) => (
                                <GridItem
                                    display="flex"
                                    key={index}
                                    border="2px solid"
                                    borderColor="elevate.500"
                                    borderRadius="md"
                                    p="4px"
                                    textAlign="center"
                                    justifyContent="center"
                                    alignItems="center"
                                    width="400px"
                                    cursor="pointer"
                                    onClick={() =>selectAssistant(assist.id)}
                                >
                                    <img
                                        src={ImageDefault}
                                        alt="profile"
                                        style={{
                                            marginBottom: '8px',
                                            width: '40px',
                                            height: '40px',
                                            marginRight: '10px',
                                            borderRadius: '50%'
                                        }}
                                    />
                                    <Text fontSize="lg" fontWeight="semibold">{assist.name}</Text>
                                </GridItem>
                            ))}
                        </Grid>
                    )}
                    {currentPage < totalPages && (
                        <Box textAlign="center" mt="6">
                            <Button color="elevate.500" text="More" onClick={handleMoreClick} />
                        </Box>
                    )}
                </>
            )}
        </Box>
    );
};

export default ChatContent;

