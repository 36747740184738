import React from "react";

interface IconProps {
  color?: string; 
}

const PositiveIcon: React.FC<IconProps> = ({ color = "#999999" }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
        >
            <path
                d="M10.9499 6.00098H15.75C16.5785 6.00098 17.25 6.67255 17.25 7.50096V9.07926C17.25 9.27516 17.2116 9.46918 17.1371 9.65038L14.8162 15.2866C14.7005 15.5676 14.4266 15.751 14.1227 15.751H1.5C1.08579 15.751 0.75 15.4152 0.75 15.001V7.50096C0.75 7.08676 1.08579 6.75098 1.5 6.75098H4.11138C4.35508 6.75098 4.58357 6.63258 4.72411 6.43349L8.81415 0.639249C8.92103 0.487847 9.12248 0.437216 9.28823 0.520095L10.6488 1.20036C11.4375 1.59474 11.8448 2.48542 11.6273 3.33999L10.9499 6.00098ZM5.25 7.94158V14.251H13.6205L15.75 9.07926V7.50096H10.9499C9.97132 7.50096 9.25485 6.57919 9.4962 5.63091L10.1737 2.96994C10.2172 2.79902 10.1357 2.62088 9.97792 2.54201L9.4821 2.29408L5.94956 7.29852C5.76214 7.56403 5.52256 7.78168 5.25 7.94158ZM3.75 8.25096H2.25V14.251H3.75V8.25096Z"
                fill={color}
            />
        </svg>
    );
};

export default PositiveIcon;
