import { useToast } from '@chakra-ui/react';

const useToastSuccess = () => {
    const toast = useToast();

    const showToast = (message: string) => {
        toast({
            title: "Success",
            description: message,
            status: "success",
            duration: 5000,
            isClosable: true,
            containerStyle: {
                color: "#ffffff",
            },
        });
    };

    return showToast;
};

export default useToastSuccess;
