import {
    VStack
} from "@chakra-ui/react";
import { Input, MultiInput } from "@canaia/ui-kit";
import { StepThreeProps } from '../types/steps.types.tsx';

const StepThree: React.FC<StepThreeProps> = ({ onComplete, updateDataAssistant, initialData, onIncomplete }) => {

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
        const newConversationStarter =
          name === "conversation_starter" ? value : initialData.conversation_starter;
        const newBehavior =
          name === "behavior" ? value : initialData.behavior;

        if (newConversationStarter.trim() && newBehavior.trim()) {
            onComplete();
        } else {
            onIncomplete(); 
        }
        updateDataAssistant(name, value);
    };
      
    const handleFrequentTopicsChange = (index: number, value: string) => {
        const updatedTopics = [...initialData.examples];
        updatedTopics[index] = value;
        updateDataAssistant("examples", updatedTopics);
    };

    return (
        <VStack spacing={4} align="start">
            <Input 
                label="Conversation starter"
                info
                infoText="texto con el que va a iniciar la conversación"
                product="elevate"
                isRequired
                name="conversation_starter"
                placeholder="Hello! I’m your personal Knowledge base. How may I help you?"
                value={initialData.conversation_starter}
                onChange={(value: string) =>
                    handleChange({ 
                        target: { name: "conversation_starter", value: value } 
                    } as React.ChangeEvent<HTMLInputElement>)
                }
                textArea={true}
            />
            <Input 
                label="Behavior"
                info
                infoText="texto para complementar la conversacion"
                product="elevate"
                isRequired
                name="behavior"
                placeholder="You are going to be helpful Knowledge base, you are going to be concise..."
                value={initialData.behavior}
                onChange={(value: string) =>
                    handleChange({ target: { name: "behavior", value: value } } as React.ChangeEvent<HTMLInputElement>)
                }
                textArea={true}
                dropdown={true}
                description={"Behavior defines how the AI Knowledge base acts and responds in conversations. It sets the AI's personality, knowledge base, and interaction style. Customize this to shape the AI's approach to suit your specific needs or preferences."}
            />
            <MultiInput
                label="Frequent Topics"
                description="Introduce frequent or common topics that users often ask so they can use them quickly."
                placeholders={["What are your opening hours?", "Are you open on Sundays?", "Another question", "Another question"]}
                values={initialData.examples}
                onChange={(index, value) => handleFrequentTopicsChange(index, value)}
                dropdown={true}
                info={true}
                infoText="This field is for specifying frequent questions."
                product="elevate"
            />
        </VStack>
    );
};

export default StepThree;
