import React from "react";

interface IconProps {
  width?: string;
  height?: string;
}

const RobotPink: React.FC<IconProps> = ({ width = "28px", height = "35px" }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 32 39"
            fill="none"
        >
            <path
                d="M15.639 5.63506C16.9514 5.89082 18.2168 5.00447 18.469 3.65069C18.717 2.2969 17.8577 0.991627 16.5453 0.731454C15.2328 0.47569 13.9674 1.36204 13.7152 2.71583C13.4673 4.06961 14.3265 5.37489 15.639 5.63506Z"
                fill="#FF6F9A"
            />
            <path
                d="M17.5023 13.1705C22.6452 13.8673 26.6295 18.4181 26.6295 23.9126C26.6295 29.8878 21.9184 34.7473 16.1215 34.7473C10.3246 34.7473 5.61356 29.8878 5.61356 23.9126C5.61356 18.4754 9.51663 13.9599 14.5868 13.1926V9.60748C8.88393 10.2513 4.20708 14.4538 2.68945 20.01C3.211 20.2437 3.6727 20.6891 4.03608 21.3153C4.5448 22.1884 4.8355 23.3526 4.8526 24.5961C4.88253 26.6466 4.18998 28.3576 3.1255 29.1558C5.1604 34.5092 10.2177 38.3103 16.1215 38.3103C22.0253 38.3103 26.9843 34.5797 29.0577 29.3101C28.643 29.0543 28.2668 28.6663 27.9633 28.1415C27.4545 27.2684 27.1638 26.1042 27.1467 24.8607C27.1296 23.6172 27.3861 22.4309 27.8735 21.5137C28.301 20.7112 28.8781 20.1688 29.5365 19.9395C27.9804 14.37 23.2479 10.1763 17.5023 9.58984V13.1749V13.1705Z"
                fill="#FF6F9A"
            />
            <path
                d="M2.81777 21.819C2.68952 21.5985 2.54845 21.4133 2.4031 21.2678C2.16797 21.0296 1.92002 20.9018 1.702 20.9106C1.6849 20.9106 1.67207 20.9106 1.65497 20.9106C1.31297 20.9547 0.915396 21.3295 0.611871 21.9072C0.20147 22.6877 -0.0122799 23.7108 0.000545138 24.8C0.0176451 25.8892 0.26132 26.8946 0.688821 27.631C1.02655 28.2043 1.45405 28.5526 1.8046 28.5394C2.08675 28.5262 2.41165 28.2836 2.69807 27.8559C3.15122 27.19 3.52315 26.0744 3.50177 24.65C3.48467 23.5608 3.241 22.5554 2.8135 21.819H2.81777Z"
                fill="#FF6F9A"
            />
            <path
                d="M31.3112 21.8189C30.9735 21.2457 30.546 20.8973 30.1954 20.9105C30.1783 20.9105 30.1655 20.9105 30.1484 20.9105C30.0372 20.9237 29.9175 20.9767 29.8021 21.0605C29.5584 21.228 29.3105 21.5235 29.1053 21.9115C28.6949 22.6832 28.4811 23.7063 28.494 24.7955C28.5111 25.8847 28.7547 26.8901 29.1822 27.6265C29.2763 27.7897 29.3789 27.9308 29.4858 28.0543C29.7551 28.3674 30.0458 28.5482 30.298 28.5349C31.0205 28.4952 32.0294 26.9739 31.9995 24.6456C31.9824 23.5564 31.7387 22.5509 31.3112 21.8145V21.8189Z"
                fill="#FF6F9A"
            />
            <path
                d="M10.9576 20.083C9.96154 20.0389 9.15356 21.525 9.15356 23.4035C9.15356 25.282 9.96154 26.8431 10.9619 26.8872C11.9622 26.9313 12.7659 25.4452 12.7659 23.5667C12.7659 21.6881 11.958 20.1271 10.9576 20.083ZM11.5818 26.3757C11.1457 26.7946 10.513 26.6667 10.1667 26.0934C9.82047 25.5202 9.88886 24.7132 10.3249 24.2943C10.761 23.8753 11.3937 24.0032 11.7399 24.5765C12.0862 25.1498 12.0178 25.9567 11.5818 26.3757Z"
                fill="#FF6F9A"
            />
            <path
                d="M20.7553 24.4432C20.4175 23.8655 19.7848 23.7288 19.3445 24.1389C18.9042 24.549 18.823 25.356 19.1607 25.9337C19.4984 26.5114 20.1311 26.6481 20.5714 26.238C21.0118 25.8279 21.093 25.0209 20.7553 24.4432Z"
                fill="#FF6F9A"
            />
            <path
                d="M19.9775 19.8603C18.9815 19.8162 18.1735 21.3023 18.1778 23.1808C18.1778 25.0594 18.99 26.616 19.9861 26.6601C20.9822 26.7042 21.7901 25.2181 21.7859 23.3396C21.7859 21.4611 20.9736 19.9044 19.9775 19.8603ZM20.5675 26.2368C20.1272 26.6469 19.4945 26.5146 19.1567 25.9325C18.819 25.3548 18.9002 24.5479 19.3406 24.1378C19.7809 23.7277 20.4136 23.8599 20.7513 24.442C21.089 25.0241 21.0078 25.8267 20.5675 26.2368Z"
                fill="#FF6F9A"
            />
            <path
                d="M16.5322 13.0888V6.87109H15.5618V13.0976C15.7499 13.0888 15.938 13.08 16.1261 13.08C16.2629 13.08 16.3997 13.0844 16.5322 13.0888Z"
                fill="#FF6F9A"
            />
        </svg>
    );
};

export default RobotPink;
