import { useLogto } from '@logto/react';
import './App.css'
import AppRoutes from './router/AppRouter.tsx'
import { useCheckTokenExpiration } from './hooks/useCheckTokenExpiration.tsx';
import { useEffect, useState } from 'react';
import { authStore } from './store/authStore.tsx';
import i18n from './translator/i18n.ts';
//import { fetchOrganization } from './api/Calls/getOrganization.tsx';
//import useToastError from './hooks/useToastError.tsx';
import { Flex, Spinner } from '@chakra-ui/react';

interface CustomData {
    language?: string;
}

function App() {
    const { isAuthenticated, fetchUserInfo } = useLogto();
    const { setUserName, setInfoUser, setOrganizationId, setLanguage } = authStore.getState();
    const { isTokenExpired } = useCheckTokenExpiration();
    const { language } = authStore((state) => state);
    const [isLoadingOrganization, setIsLoadingOrganization] = useState(false);
    //const showToastError = useToastError();
    const { resetAuthState } = authStore.getState();

    useEffect(() => {
        if (i18n.language !== language) {
            i18n.changeLanguage(language);
        }
    }, [language]);

    useEffect(() => {
        if(!isAuthenticated){
            resetAuthState();
        }
        const checkTokenAndSignOut = async () => {
            setIsLoadingOrganization(true);
            if (isAuthenticated) {
                const userInfo = await fetchUserInfo();
                setInfoUser({
                    email: userInfo?.email || ""
                })
                setOrganizationId("i3isplfdk800");
                //const token = await getAccessToken(import.meta.env.VITE_LOGTO_API_ID);
                //if (token){

                // await fetchOrganization(token, signOut, showToastError).then((response: { id: string }[]) => {
                //     console.log('la respuesta de fetch organization', response)
                //     if (response.length > 0) {
                //         setOrganizationId(response[0].id);
                //     } else {
                //         setOrganizationId("");
                //     }
                // })
                   
                //}
                const customData = userInfo?.custom_data as CustomData;
                const preferredLanguage = customData?.language || navigator.language;
                const normalizedLanguage = preferredLanguage.includes("-ES") ? "es" : preferredLanguage.split("-")[0] === "es" ? "es" : "en";
                if (i18n.language !== normalizedLanguage) {
                    i18n.changeLanguage(normalizedLanguage)
                    setLanguage(normalizedLanguage);
                }
                setUserName(userInfo?.name || "no llego");
            }
        };

        checkTokenAndSignOut().finally(() => {
            setIsLoadingOrganization(false)
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, isTokenExpired]);

    if (isLoadingOrganization) {
        return (
            <Flex justify="center" align="center" height="100vh">
                <Spinner size="xl" color="teal.500" />
            </Flex>
        );
    }
    
    return (
        <>
            <AppRoutes/>
        </>
    )
}

export default App;