import createApiClient from "../../ApiClients";


interface CreateAssistantParams {
    name: string;
    description: string;
    conversation_starter?: string,
    behavior?: string,
    examples?: string[],
    files_ids?: number[]
  }
  
export const createAssistant = async (
    token: string,
    signOut: () => Promise<void>,
    showToast: (message: string) => void,
    assistantData: CreateAssistantParams
) => {
    const apiClient = createApiClient(token, signOut, showToast);
  
    const response = await apiClient.post('/assistants', assistantData);
  
    return response.data;
};
  