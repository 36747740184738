import createApiClient from "../../ApiClients";

export const deleteFile = async (
    token: string,
    signOut: () => Promise<void>,
    showToastError: (message: string) => void,
    fileId: string | number
) => {

    const apiClient = createApiClient(token, signOut, showToastError);
    const response = await apiClient.delete(`/files/${fileId}`);
    
    return response.data;
};
