import React from "react";
import {
    Box,
    Button,
    Flex,
    Heading,
    VStack,
} from "@chakra-ui/react";
import LogoElevate from "../../icons/logo/logoElevate";
import RobotIcon from "../../icons/robot";
import { useTranslation } from "react-i18next";

const Welcome: React.FC = () => {
    const { t } = useTranslation();
    return (
        <Flex alignItems="center" justifyContent="center" height="100%">
            <VStack spacing={6}>
                <Box mb="50px">
                    <LogoElevate />
                </Box>
               
                <RobotIcon width={85} height={97} />
                <Heading
                    as="h1"
                    textAlign="center"
                    fontSize="38px"
                    color="black"
                    w="750px"
                >
                    {(t('leaveItToCanaiaCreateYourFirstKnowledgeDatabase'))}
                </Heading>

                <Button
                    size="lg"
                    bg="pink.400"
                    color="white"
                    _hover={{ bg: "pink.500" }}
                    mt="40px"
                >
                    {(t('createKnowledgeDatabase'))}
                </Button>
            </VStack>
        </Flex>
    );
};

export default Welcome;
