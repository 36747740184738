import React from "react";
import {
    Box,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Text,
    VStack,
    HStack,
} from "@chakra-ui/react";
import { FaFilePdf } from "react-icons/fa";
import { FileTablePropsStepFour } from "../types/steps.types.tsx";
import { useTranslation } from "react-i18next";

const FileTableStepFour: React.FC<FileTablePropsStepFour> = ({files}) => {
    const { t } = useTranslation();

    return (
        <Box borderRadius="md" w="100%">
            <VStack spacing={4} align="stretch">
                <HStack justifyContent="space-between" align="center" w="100%" px={2}  >
                    <Text fontSize="xl" fontWeight="bold">
                        {t('Files')}
                    </Text>
                </HStack>
                <Table variant="simple" bg="white" borderRadius="16px" boxShadow="sm" p={8}>
                    <Thead>
                        <Tr>
                            <Th>{t('name')}</Th>
                            <Th>{t('KnowledgeBases')}</Th>
                            <Th>{t('lastMofificated')}</Th>
                            <Th></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {files.map((file, index) => (
                            <Tr key={index}>
                                <Td>
                                    <Box display="flex" alignItems="center">
                                        <FaFilePdf 
                                            style={{
                                                marginRight: "8px",
                                                color: "pink"
                                            }}
                                        />
                                        {file.name}
                                    </Box>
                                </Td>
                                <Td>{file.nameAssist}</Td>
                                <Td>{file.lastModified}</Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </VStack>
        </Box>
    );
};

export default FileTableStepFour;
