import React from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    IconButton,
    Box,
    Text,
    HStack,
    useTheme,
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { ConversationsTableProps } from './conversations.types';
import { useTranslation } from 'react-i18next';
import FormattedDate from '../FomattedDate';
import PositiveIcon from '../../../icons/feedback/positive';
import NegativeIcon from '../../../icons/feedback/negative';

const ConversationsTable: React.FC<ConversationsTableProps> = ({
    data,
    currentPage,
    pageSize,
    totalItems,
    onPageChange,
    clickShowModal,
}) => {
    const { t } = useTranslation();
    const totalPages = Math.ceil(totalItems / pageSize);
    const theme = useTheme();
    return (
        <Box bg="white" rounded="md" shadow="md" p={4}>
            <Table variant="simple">
                <Thead>
                    <Tr>
                        <Th>{t('User')}</Th>
                        <Th>{t('KnowledgeBase')}</Th>
                        <Th>{t('Conversation')}</Th>
                        <Th>{t('Date')}</Th>
                        <Th>{t('Feedback')}</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {data.map((item) => (
                        <Tr 
                            key={item.id}
                            onClick={() => clickShowModal(item.id)}
                            cursor="pointer"
                        >
                            <Td>
                                <Text fontWeight="semibold" cursor="pointer" color="blue.500">
                                    {item.user.name}
                                </Text>
                            </Td>
                            <Td>{item.assistant.name}</Td>
                            <Td>{item.title}</Td>
                            <Td>
                                <FormattedDate isoDate={item.updated_at} />
                            </Td>

                            <Td>
                                <HStack spacing={2}>
                                    <HStack spacing={1}>
                                        <PositiveIcon color={item.feedback.positive > 0 ? theme.colors.success[400] : undefined} />
                                        <Text>{item.feedback.positive}</Text>
                                    </HStack>
                                    <HStack spacing={1}>
                                        <NegativeIcon color={item.feedback.negative > 0 ? theme.colors.danger[400] : undefined} />
                                        <Text>{item.feedback.negative}</Text>
                                    </HStack>
                                </HStack>
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>

            {totalPages > 1 && (
                <HStack justify="flex-end" spacing={2} mt={4}>
                    <IconButton
                        aria-label={t('PreviousPage')}
                        icon={<ChevronLeftIcon />}
                        onClick={() => onPageChange(currentPage - 1)}
                        isDisabled={currentPage === 1}
                        variant="outline"
                        size="sm"
                    />
                    <Text>
                        {currentPage} / {totalPages}
                    </Text>
                    <IconButton
                        aria-label={t('NextPage')}
                        icon={<ChevronRightIcon />}
                        onClick={() => onPageChange(currentPage + 1)}
                        isDisabled={currentPage === totalPages}
                        variant="outline"
                        size="sm"
                    />
                </HStack>
            )}
        </Box>
    );
};

export default ConversationsTable;
