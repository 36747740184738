import React, { useEffect, useState } from 'react';
import {
    Box,
    Text,
    IconButton,
    Flex
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { DragAndDrop, CustomProgressBar } from '@canaia/ui-kit'; 
import { FileData } from '../../../../ui/components/ModalUploaderFiles/modal.types.tsx';
import { useTranslation } from 'react-i18next';
import {StepTwoProps} from '../types/steps.types.tsx';

const StepTwo: React.FC<StepTwoProps> = ({ 
    onComplete, 
    updateFiles, 
    onIncomplete,
    setFilesDataProgess,
    filesDataProgess
}) => {
    const { t } = useTranslation();
    const [files, setFiles] = useState<FileData[]>(filesDataProgess);

    useEffect(() => {
        setFiles(filesDataProgess);
    }, [filesDataProgess]);

    const syncFilesWithParent = (updatedFiles: FileData[]) => {
        setFiles(updatedFiles);
        setFilesDataProgess(updatedFiles);
        updateFiles(updatedFiles);
    };

    const handleFilesAdded = (newFiles: File[]) => {
        const mapped = newFiles.map(file => ({
            file: file,
            name: file.name,
            size: file.size,
            progress: 0
        }));
        syncFilesWithParent([...files, ...mapped]);
    };

    const handleRemoveFile = (index: number) => {
        const updatedFiles = files.filter((_, i) => i !== index);
        syncFilesWithParent(updatedFiles);
    };

    const isAnyFileUploading = files.some(file => file.progress === 100);

    useEffect(() => {
        if(isAnyFileUploading && files.length > 0) {
            onComplete();
        } else {
            onIncomplete();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [files, isAnyFileUploading]);
  
    useEffect(() => {
        const intervals = files.map((file, index) => {
         
            if (file.progress >= 100) return null;
    
            const interval = setInterval(() => {
                setFiles(prevFiles => {
                    const newFiles = [...prevFiles];
                    const currentFile = newFiles[index];
                    if (currentFile.progress >= 100) {
                        clearInterval(interval);
                        return newFiles;
                    }
                    currentFile.progress += 10;
                    if (currentFile.progress > 100) currentFile.progress = 100;
                    return newFiles;
                });
            }, 200);
    
            return interval;
        });
    
        return () => {
            intervals.forEach(interval => {
                if (interval) clearInterval(interval);
            });
        };
    }, [files]);
    
    return (
        <Box  display="flex" background="white" p="24px" borderRadius="16px">
            <Box
                mr={4}
                width={files.length > 0 ? '50%' : '100%'}
                textAlign="center"
            >
                <DragAndDrop 
                    color="elevate.500"
                    description={t('DragYourFilesHere')}
                    buttonText={t('UploadFile')}
                    onFilesDropped={handleFilesAdded}
                    typeAccept="application/pdf"
                    fileAccept={[".pdf"]}
                />
            </Box>
  
            {files.length > 0 && (
                <Flex flexDir="column" py="15px">
                    {files.map((file, index) => (
                        <Box
                            key={`${file.name}-${index}`}
                            p="18px"
                            border="1px solid"
                            borderColor="gray.200"
                            borderRadius="md"
                            mb="10px"
                            width="350px"
                            minW="110px"
                        >
                            <Text fontWeight="semibold" >{file.name}</Text>
                            <Text fontSize="sm" color="gray.600" mb={4}>
                                {Math.round(file.size / 1024)} KB
                            </Text>
                            <CustomProgressBar
                                value={file.progress}
                                backgroundColor="balck.40"
                                size='sm'
                                status={file.progress === 100 ? 'completed' : 'loading'}
                            />
                            <IconButton
                                icon={<CloseIcon boxSize="8px"/>}
                                aria-label={t('RemoveFile')}
                                size="3px"
                                variant="ghost"
                                onClick={() => handleRemoveFile(index)}
                                float="right"
                                mt={-10}
                                _hover={{ background: "transparent" }}
                            />
                        </Box>
                    ))}
                </Flex>
            )}
         
        </Box>
          
    );
};

export default StepTwo;
