import React from 'react';

interface IconProps {
  width?: number;
  height?: number;
  color?: string;
}

const KnowledgeBase: React.FC<IconProps> = ({ width = 22, height = 24, color="#666666" }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 22 24"
            fill="none"
        >
            <path
                d="M10.7616 3.15604C11.6252 3.31919 12.4578 2.75379 12.6238 1.89021C12.787 1.02664 12.2216 0.194002 11.358 0.0280381C10.4944 -0.135113 9.66178 0.43029 9.49581 1.29387C9.33266 2.15744 9.89806 2.99007 10.7616 3.15604Z" 
                fill={color}
            />
            <path
                d="M11.9881 7.9638C15.3721 8.40825 17.9937 11.3112 17.9937 14.8161C17.9937 18.6277 14.8939 21.7276 11.0795 21.7276C7.26514 21.7276 4.16527 18.6277 4.16527 14.8161C4.16527 11.3478 6.73349 8.46732 10.0697 7.97787V5.69094C6.31718 6.10163 3.23981 8.78237 2.24121 12.3267C2.58439 12.4758 2.88819 12.7599 3.12729 13.1593C3.46203 13.7163 3.65331 14.4589 3.66456 15.2522C3.68425 16.5602 3.22856 17.6516 2.52813 18.1607C3.8671 21.5757 7.19482 24.0004 11.0795 24.0004C14.9642 24.0004 18.2272 21.6207 19.5915 18.2592C19.3186 18.096 19.0711 17.8485 18.8714 17.5138C18.5366 16.9568 18.3454 16.2142 18.3341 15.4209C18.3228 14.6277 18.4916 13.871 18.8123 13.2859C19.0936 12.7739 19.4733 12.428 19.9065 12.2817C18.8826 8.72892 15.7687 6.05381 11.9881 5.67969V7.96662V7.9638Z"
                fill={color}
            />
            <path
                d="M2.32577 13.4801C2.24138 13.3395 2.14856 13.2213 2.05292 13.1285C1.8982 12.9766 1.73505 12.895 1.59159 12.9007C1.58034 12.9007 1.5719 12.9007 1.56065 12.9007C1.33561 12.9288 1.07401 13.1679 0.87429 13.5364C0.604247 14.0343 0.4636 14.6869 0.472038 15.3817C0.48329 16.0765 0.643628 16.7178 0.924923 17.1876C1.14715 17.5533 1.42844 17.7755 1.6591 17.7671C1.84476 17.7586 2.05854 17.6039 2.24701 17.3311C2.54518 16.9063 2.78991 16.1946 2.77584 15.286C2.76459 14.5912 2.60425 13.9499 2.32296 13.4801H2.32577Z"
                fill={color}
            />
            <path
                d="M21.0738 13.4801C20.8516 13.1144 20.5703 12.8922 20.3396 12.9006C20.3284 12.9006 20.3199 12.9006 20.3087 12.9006C20.2356 12.9091 20.1568 12.9428 20.0808 12.9963C19.9205 13.1032 19.7574 13.2916 19.6223 13.5392C19.3523 14.0314 19.2116 14.684 19.2201 15.3788C19.2313 16.0736 19.3917 16.715 19.673 17.1847C19.7349 17.2888 19.8024 17.3788 19.8727 17.4576C20.0499 17.6573 20.2412 17.7727 20.4072 17.7642C20.8825 17.7389 21.5464 16.7684 21.5267 15.2832C21.5155 14.5884 21.3551 13.947 21.0738 13.4773V13.4801Z"
                fill={color}
            />
            <path
                d="M7.68218 12.3737C7.02676 12.3455 6.49512 13.2935 6.49512 14.4918C6.49512 15.6901 7.02676 16.6859 7.685 16.714C8.34323 16.7422 8.87206 15.7942 8.87206 14.5959C8.87206 13.3976 8.34041 12.4018 7.68218 12.3737ZM8.09287 16.3877C7.80595 16.655 7.38964 16.5734 7.16179 16.2077C6.93394 15.842 6.97894 15.3273 7.26587 15.06C7.55279 14.7928 7.9691 14.8744 8.19695 15.2401C8.4248 15.6057 8.37979 16.1205 8.09287 16.3877Z"
                fill={color}
            />
            <path
                d="M13.6169 12.2311C12.9615 12.2029 12.4298 13.1509 12.4326 14.3492C12.4326 15.5475 12.9671 16.5405 13.6225 16.5686C14.2779 16.5968 14.8096 15.6488 14.8068 14.4505C14.8068 13.2522 14.2723 12.2592 13.6169 12.2311ZM14.0051 16.2986C13.7153 16.5602 13.299 16.4758 13.0768 16.1045C12.8546 15.736 12.908 15.2212 13.1978 14.9596C13.4875 14.698 13.9038 14.7824 14.126 15.1537C14.3482 15.525 14.2948 16.037 14.0051 16.2986Z"
                fill={color}
            />
            <path
                d="M11.3495 7.90962V3.94336H10.7109V7.91524C10.8347 7.90962 10.9585 7.90399 11.0822 7.90399C11.1723 7.90399 11.2623 7.90681 11.3495 7.90962Z"
                fill={color}
            />
        </svg>
    );
};

export default KnowledgeBase;
