import createApiClient from "../../ApiClients";

interface sendData {
    name: string;
    description: string;
}

export const editFile = async (
    token: string,
    signOut: () => Promise<void>,
    showToast: (message: string) => void,
    fileId: number,
    fileData: sendData
) => {
    const apiClient = createApiClient(token, signOut, showToast);
    const response = await apiClient.patch(`/files/${fileId}`, fileData);

    return response.data;
};
