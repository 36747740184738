import createApiClient from '../../ApiClients';

interface GetFileUrlParams {
  file_id: number;
}

interface FileUrlResponse {
  url: string;
}

const getFileUrl = async (
    token: string,
    signOut: () => Promise<void>,
    showToast: (message: string) => void,
    { file_id }: GetFileUrlParams
): Promise<FileUrlResponse> => {
    const apiClient = createApiClient(token, signOut, showToast);

    const response = await apiClient.get(`/files/${file_id}/url`);
    return response.data;
};

export default getFileUrl;