import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Box, Flex } from "@chakra-ui/react";
import { authStore } from "../../store/authStore";
import { Header, SideBar } from "@canaia/ui-kit";
import { useLogto } from "@logto/react";
import { IconChat, IconConversations, IconFiles, IconDashboard, IconRobot } from "../../icons/sidebar/index";
import { useTranslation } from "react-i18next";

const Layout: React.FC = () => {
    const { signOut } = useLogto();
    const { organizationId, userName } = authStore.getState();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const location = useLocation();
   
    const LogOut = () => {
        signOut(window.location.origin + "/")
    }

    const HeaderProps = {
        platform: "elevate",
        color: "elevate.400",
        settingsName: "settings",
        showMenuItems: ['hub','play','assist','elevate'],
        onSettingsClick: () => window.location.href = 'https://staging.hub.canaia.ai/settings',
        onLogOutClick: () => LogOut(),
        userName: userName ? userName : "User Name",
    };

    const SidebarProps = { menuOptions: [
        {
            title: t('KnowledgeBases'),
            icon: <IconRobot />,
            iconHover: <IconRobot color={"#FF6F9A"}  />,
            onClick: () => navigate('/'),
            url:'/'
        },
        {
            title: t('Chat'),
            icon: <IconChat />,
            iconHover: <IconChat color={"#FF6F9A"}  />,
            onClick: () => navigate('/chat'),
            url:'/chat'
        },
        {
            title: t('Conversations'),
            icon: <IconConversations />,
            iconHover: <IconConversations color={"#FF6F9A"}  />,
            onClick: () => navigate('/conversations'),
            url:'/conversations'
        },
        {
            title: t('Files'),
            icon: <IconFiles />,
            iconHover: <IconFiles color={"#FF6F9A"}  />,
            onClick: () => navigate('/files'),
            url:'/files'
        },
        {
            title: t('Dashboard'),
            icon: <IconDashboard />,
            iconHover: <IconDashboard color={"#FF6F9A"}  />,
            onClick: () => navigate('/dashboard'),
            url:'/dashboard'
        },
    ]};

    const isChatRoute = location.pathname === '/chat';

    return (
        <>
            <Header {...HeaderProps}/>
            <Flex width="100%">
                {organizationId && <SideBar {...SidebarProps}  currentUrl={location.pathname} />}
                <Box 
                    flex="1"
                    pt={isChatRoute ? "75px" : "130px"} 
                    px={isChatRoute ? "0" : "96px"} 
                    bg="elevate.200"
                >
                    <Outlet />
                </Box>
            </Flex>
        </>
    );
};

export default Layout;
