import createApiClient from '../../ApiClients';

interface CreateConversationParams {
  assistant_id: number;
}

export const postConversation = async (
    token: string,
    signOut: () => Promise<void>,
    showToast: (message: string) => void,
    { assistant_id }: CreateConversationParams
) => {
    const apiClient = createApiClient(token, signOut, showToast);

    const response = await apiClient.post('/conversations', {
        assistant_id,
    });
    return response.data;
  
};
