import { useEffect, useState } from 'react';
import {
    Box,
    Heading,
    Flex,
    InputGroup,
    Input,
    InputLeftElement,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { Button, Spinner } from '@canaia/ui-kit';
import  { searchAssistants } from '../../api/Calls/KnowledgeBase/searchAssistants';
import KnowledgeBasesTable from '../../ui/components/TableKnowledge';
import { getAssistants } from '../../api/Calls/getAssistansTable';
import { useGetTokenWithOrganization } from '../../hooks/useGetTokenWithOrganization';
import { useLogto } from '@logto/react';
import  useToastError  from "../../hooks/useToastError";
import {KnowledgeBaseItem } from './KnowledgeBaseItem.types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function KnowledgeBasesPage() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { signOut } = useLogto();
    const showError = useToastError();
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [assistants, setAssistants] = useState<KnowledgeBaseItem[]>([]); 
    const { fetchUpdatedToken } = useGetTokenWithOrganization();
    const [isLoading, setIsLoading] = useState(true);
    const [updateData, setUpdateData] = useState(false);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const handleSearch = async (e: { key: string; }) => {
        if (e.key === 'Enter') {
            if (!searchTerm.trim()) {
                showError(t('searchQuestionWithOutLetter'));
                return;
            }
            setIsLoading(true);
 
            const token = await fetchUpdatedToken();
    
            if (!token) return;
    
            const response = await searchAssistants({ q: searchTerm, page: currentPage, size: pageSize }, token, signOut, showError);
    
            setAssistants(response.items);
            setTotalItems(response.total);
    
            setIsLoading(false);
          
        }
    };
    
    useEffect(() => {
        loadAssistants();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    useEffect(() => {
        if (searchTerm.trim() === '') {
            loadAssistants();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm]);

    useEffect(() => {
        if(updateData){
            loadAssistants();
            setUpdateData(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateData]);

    const loadAssistants = async () => {
        const token = await fetchUpdatedToken();
        if (!token) {
            setIsLoading(false);
            return;
        }
        const params = {page: currentPage, size: pageSize};
        const data = await getAssistants(token, signOut, showError, params);
        setTotalItems(data.total);
        setTotalPages(data.pages)
        setAssistants(data.items);
        setIsLoading(false);
    
    };
    const clickNewKnowledgeBase = () => {
        navigate("/create-knoweledge-base")
    }
      
    return (
        <Box
            minH="100vh"
            p={4}
        >
            {isLoading && <Spinner />}
            <Heading mb="40px" textAlign="left" fontSize="36px">{t('KnowledgeBases')}</Heading>
            <Flex
                justify="flex-end"
                align="center"
                mb={4}
            >
                <Button color="elevate.500" text={t('NewKnowledgeBase')} onClick={clickNewKnowledgeBase}/>
                <InputGroup w="300px" ml={4}>
                    <InputLeftElement pointerEvents="none">
                        <SearchIcon color="gray.400" />
                    </InputLeftElement>
                    <Input
                        placeholder={t('search')+ '...'}
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                        onKeyDown={handleSearch}
                        bg={"white"}
                    />
                </InputGroup>
            </Flex>
            {assistants?.length === 0 
                ?  <Box>{t('Nodata')}</Box> 
                :
                <KnowledgeBasesTable
                    data={assistants ? assistants : []}              
                    currentPage={currentPage}        
                    pageSize={pageSize}             
                    totalItems={totalItems} 
                    onPageChange={handlePageChange}
                    totalPages={totalPages}
                    setUpdateData={setUpdateData}
                />
            }
         
        </Box>
    );
}

export default KnowledgeBasesPage;
