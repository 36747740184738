import {
    Box,
    Flex,
    Stack,
    Switch,
    Text,
    useDisclosure,
    VStack,
} from "@chakra-ui/react";
import { PhotoUpload, Input, Button, MultiInput } from "@canaia/ui-kit";
import Table from "./tableFiles.tsx";
import { FC, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import  DangerZone from "./dangerZone.tsx"; 
import { useGetTokenWithOrganization } from "../../../hooks/useGetTokenWithOrganization.tsx";
import { deleteAssistant } from "../../../api/Calls/KnowledgeBase/delete.tsx";
import { useLogto } from "@logto/react";
import useToastError from "../../../hooks/useToastError.tsx";
import useToastSuccess from "../../../hooks/useToastSuccess.tsx";
import { useNavigate } from "react-router-dom";
import ModalConfirm from "../ModalConfirm/index.tsx";
import {EditKnowledgeBaseFormProps} from "./types/index.tsx";
import ModalUploaderFiles from "../ModalUploaderFiles/index.tsx";

const EditKnowledgeBaseForm: FC<EditKnowledgeBaseFormProps> = (
    { handleSave,
        defaultValues,
        handleInputChange,
        isLoading,
        isStatus, 
        handleChangeStatus,
        files,
        handleDeleteFile,
        setIsLoading,
        getFileNew
    }) => {
    const { t } = useTranslation();
    const { signOut } = useLogto();
    const showError = useToastError();
    const showToastSuccess = useToastSuccess(); 
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [itemToDelete, setItemToDelete] = useState<number>(0);
    const {fetchUpdatedToken } = useGetTokenWithOrganization();
    const navigate = useNavigate();
    const cancelRef = useRef<HTMLButtonElement>(null);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const goBack = () => {
        navigate(-1)
    }

    const allRequiredFilled =
    defaultValues.name.trim().length > 0 &&
    defaultValues.conversation_starter.trim().length > 0;

    const handleDelete = async () => {
        if (!itemToDelete) return;

        const token = await fetchUpdatedToken();
        if (token) {
            await deleteAssistant(token, signOut, showError, itemToDelete);
        } else {
            setItemToDelete(0);
            setIsDeleteModalOpen(false);
        }
        showToastSuccess(`${t('hasBeenDeletedSuccessfully')}`)
        setItemToDelete(0);
        setIsDeleteModalOpen(false);
        navigate('/')
    };

    const handleDeleteClick = (item: number) => {
        setItemToDelete(item);
        setIsDeleteModalOpen(true);
    };

    const handleCancel = () => {
        setItemToDelete(0);
        setIsDeleteModalOpen(false);
    };

    return (
        <Box 
            minHeight="100vh" 
            p={8} 
            maxW="900px" 
            margin="auto" 
        >
            <VStack spacing={8} align="stretch">
                <Flex justify="space-between" align="center">
                    <Text fontSize="24px" fontWeight="700">{(t('EditKnowledgeBase'))}</Text>
                    <Text fontSize="12px" fontWeight="700">
                        {t('Status')}    
                        <Switch isChecked={isStatus} onChange={handleChangeStatus} px="5px" colorScheme="green"/>
                        {t('Active')}
                    </Text>
                 
                </Flex>
           
                <Flex justify="space-between" align="center">
                 
                    <Stack spacing={4} mb={8} mr={8} flex="1" mt={2}>
                        <Text textAlign="left" fontSize="16px" fontWeight="700"> {t('Image')} </Text>
                        <PhotoUpload
                            description={(t('UploadImage'))}
                            iconColor="elevate.500"
                            onUpload={(file: File) => console.log('Por ahora no lo mandamos al formulario.', file)}
                        />
                    </Stack>
                    <Stack spacing={4} mb={8} flex="4">
                        <Input
                            label={(t('name'))}
                            placeholder={(t('name'))}
                            isRequired
                            info
                            infoText={(t('enterTheNameOfYourDatabase'))}
                            product="elevate"
                            value={defaultValues.name}
                            onChange={(value: string) =>
                                handleInputChange("name", value)
                            }
                        />
                        <Input
                            label={(t('description'))}
                            placeholder={(t('companyDescription'))}
                            info
                            infoText={(t('enterADescriptionOfThisDatabase'))}
                            product="elevate"
                            value={defaultValues.description}
                            onChange={(value: string) =>
                                handleInputChange("description", value)
                            }
                            textArea={true}
                        />
                    </Stack>
                </Flex>

                <VStack spacing={4} align="stretch">
                    <Input
                        label={(t('conversationStarter'))}
                        placeholder={(t('helloImIrinaYourVirtualKnowledgeBaseHereToAssistYouWithAllMattersRelatedToYourCompensationAndBenefitsHowCanIHelpYouToday'))}
                        info
                        infoText={(t('addConversationStartersToHelpUsers'))}
                        product="elevate"
                        value={defaultValues.conversation_starter}
                        onChange={(value: string) =>
                            handleInputChange("conversation_starter", value)
                        }
                        textArea={true}
                        dropdown={true}
                        description={(t('addConversationStartersToHelpUsers'))}
                    />
                </VStack>
                <Stack spacing={1} mb={8}>
                    <Input
                        label={(t('behavior'))}
                        placeholder={(t('YouAreGoingToBeHelpfulKnowledgeBaseYouAreGoingToBeConcise'))}
                        info
                        infoText={(t('behaviorDefinesHowTheAIKnowledgeBaseActsAndRespondsInConversations'))}
                        product="elevate"
                        value={defaultValues.behavior}
                        onChange={(value: string) =>
                            handleInputChange("behavior", value)
                        }
                        textArea={true}
                        dropdown={true}
                        description={(t('behaviorDefinesHowTheAIKnowledgeBaseActsAndRespondsInConversationsItSetsTheAI'))}
                    />
                </Stack>
                <Stack spacing={2} mb={8} mr={8} flex="2">
                    <MultiInput
                        label={(t('FrequentTopics'))}
                        description={(t('IntroduceFrequentOrCommonTopicsThatUsersOftenAskSoTheyCanUseThemQuickly'))}
                        placeholders={[(t('WhatAreYourOpeningHours')), (t('AreYouOpenOnSundays')), "Another question","Another question"]}
                        dropdown={true}
                        info={true}
                        infoText={(t('ThisFieldIsForSpecifyingFrequentQuestions'))}
                        product="elevate"
                        values={defaultValues.examples}
                        onChange={(index, newVal) => {
                            const updatedTopics = [...defaultValues.examples];
                            updatedTopics[index] = newVal;
                            handleInputChange("examples", updatedTopics);
                        }}
                    />
                </Stack>
                <Stack spacing={2} mb={8} flex="1">
                    <Table files={files} handleDeleteFile={handleDeleteFile} onOpen={onOpen} />
                    <ModalUploaderFiles
                        isOpen={isOpen}
                        onClose={onClose}
                        setIsLoading={setIsLoading}
                        getMeFunction={getFileNew}
                    />
                </Stack>
    
                <Flex justify="space-between">
                    <Button
                        color="elevate.500"
                        type="outline"
                        onClick={() => goBack()}
                        text={(t('Back'))}
                    />
                    <Button 
                        color="elevate.500"
                        onClick={handleSave}
                        text={(t('Save'))}
                        isLoading={isLoading}
                        disabled={!allRequiredFilled}
                    />
                </Flex> 
                <DangerZone 
                    handleDeleteClick={handleDeleteClick}
                    item={defaultValues.id}
                />
                {isDeleteModalOpen 
                && <ModalConfirm
                    onConfirm={handleDelete}
                    onCancel={handleCancel}
                    isOpen={isDeleteModalOpen}
                    cancelRef={cancelRef}
                />}
            </VStack>
        </Box>
    );
};

export default EditKnowledgeBaseForm;