import React, { useEffect, useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Box,
    Text,
    IconButton,
    Stack,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { DragAndDrop, Button, CustomProgressBar } from '@canaia/ui-kit'; 
import { createFile } from '../../../api/Calls/files/createFile';
import { useLogto } from '@logto/react';
import { useGetTokenWithOrganization } from '../../../hooks/useGetTokenWithOrganization.tsx';
import useToastError from '../../../hooks/useToastError.tsx';
import useToastSuccess from '../../../hooks/useToastSuccess.tsx';
import { UploadFilesModalProps, FileData } from './modal.types.tsx';
import { useTranslation } from 'react-i18next';

const UploadFilesModal: React.FC<UploadFilesModalProps> = ({
    isOpen, 
    onClose,
    UploaderSuccess,
    setIsLoading,
    getMeFunction,
    getMeFile
}) => {
    const { t } = useTranslation();
    const [files, setFiles] = useState<FileData[]>([]);
    const [isUploading, setIsUploading] = useState(false);
    const {signOut} = useLogto();
    const { fetchUpdatedToken } = useGetTokenWithOrganization();
    const showError = useToastError();
    const showToastSuccess = useToastSuccess();

    const handleFilesAdded = (newFiles: File[]) => {
        const mapped = newFiles.map(file => ({
            file: file,
            name: file.name,
            size: file.size,
            progress: 0
        }));
        setFiles(prev => [...prev, ...mapped]);
    };
    const isAnyFileUploading = files.some(file => file.progress < 100);

    const handleRemoveFile = (index: number) => {
        setFiles(prev => prev.filter((_, i) => i !== index));
    };

    const onClickNext = async () => {
        setIsUploading(true);
        if (setIsLoading) {
            setIsLoading(true);
        }
        onClose();

        for (const fileData of files) {
            const formData = new FormData();
            formData.append('file', fileData.file);
            formData.append('metadata', JSON.stringify({name: fileData.name})); 
            if(getMeFunction){
                getMeFunction({ file: fileData.file, metadata: { name: fileData.name } });
                showToastSuccess(t('fileSploadedSuccessfully'));
                setFiles([]);
                onClose();
                setIsUploading(false);
                if (setIsLoading) {
                    if (setIsLoading) {
                        setIsLoading(false);
                    }
                }
            }else if(getMeFile){
                getMeFile(files)
                setIsUploading(false);
                setFiles([]);
            } else {
                try {
                    const token = await fetchUpdatedToken();
                    if (token) {
                        const createFilesBody = {
                            file: fileData.file,
                            metadata: { name: fileData.name }
                        };
                        await createFile(token, signOut, showError, createFilesBody);
                        showToastSuccess(t('fileSploadedSuccessfully'));
                        if (UploaderSuccess) UploaderSuccess();
                  
                    } 
                    setFiles([]);
                    onClose();
                }finally{
                    setIsUploading(false);
                    if (setIsLoading) {
                        setIsLoading(false);
                    }
                   
                }
            }
        }
    }

  
    useEffect(() => {
        const intervals = files.map((file, index) => {
         
            if (file.progress >= 100) return null;
    
            const interval = setInterval(() => {
                setFiles(prevFiles => {
                    const newFiles = [...prevFiles];
                    const currentFile = newFiles[index];
                    if (currentFile.progress >= 100) {
                        clearInterval(interval);
                        return newFiles;
                    }
                    currentFile.progress += 10;
                    if (currentFile.progress > 100) currentFile.progress = 100;
                    return newFiles;
                });
            }, 500);
    
            return interval;
        });
    
        return () => {
            intervals.forEach(interval => {
                if (interval) clearInterval(interval);
            });
        };
    }, [files]);

    const clickCancel = () => {
        setFiles([]);
        onClose();
    }
    
    return (
        <Modal isOpen={isOpen} onClose={onClose} size="lg">
            <ModalOverlay />
            <ModalContent bg="white" p={4} minW="680px" borderRadius={10}>
                <ModalHeader textAlign="left">{t('UploaderYourFiles')}</ModalHeader>
                <ModalCloseButton onClick={clickCancel}/>
                <ModalBody p={8}>
                    <Box
                        p={4}
                        mb={4}
                        textAlign="center"
                    >
                        <DragAndDrop 
                            color="elevate.500"
                            title={t('UploadingFiles')}
                            description={t('DragYourFilesHere')}
                            buttonText={t('UploadFile')}
                            onFilesDropped={handleFilesAdded}
                            typeAccept="application/pdf"
                            fileAccept={[".pdf"]}
                        />
                    </Box>

                    <Stack spacing={3}>
                        {files.map((file, index) => (
                            <Box
                                key={`${file.name}-${index}`}
                                p="18px"
                                border="1px solid"
                                borderColor="gray.200"
                                borderRadius="md"
                            >
                                <Text fontWeight="semibold" >{file.name}</Text>
                                <Text fontSize="sm" color="gray.600" mb={4}>
                                    {Math.round(file.size / 1024)} KB
                                </Text>
                                <CustomProgressBar
                                    value={file.progress}
                                    backgroundColor="balck.40"
                                    size='sm'
                                    status={file.progress === 100 ? 'completed' : 'loading'}
                                />
                                <IconButton
                                    icon={<CloseIcon boxSize="8px"/>}
                                    aria-label={t('RemoveFile')}
                                    size="3px"
                                    variant="ghost"
                                    onClick={() => handleRemoveFile(index)}
                                    float="right"
                                    mt={-10}
                                    _hover={{ background: "transparent" }}
                                />
                            </Box>
                        ))}
                    </Stack>
                </ModalBody>

                <ModalFooter justifyContent="space-between">
                    <Button 
                        color="elevate.500"
                        type="outline"
                        onClick={clickCancel}
                        text={t('Cancel')}
                    />
                    <Button
                        color="elevate.500"
                        onClick={onClickNext}
                        text={t('next')}
                        isLoading={isUploading} 
                        disabled={isUploading || isAnyFileUploading || files.length === 0}
                    />
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default UploadFilesModal;
