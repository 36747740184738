import { Outlet } from "react-router-dom";
import { useLogto } from "@logto/react";
import { useEffect, useState } from "react";
import { authStore } from "../store/authStore";
import { Spinner } from "@chakra-ui/react";


const ProtectedRoute = () => {
    const { signIn, isAuthenticated } = useLogto();
    const [isLoading, setIsLoading] = useState(true);
    const [organizationId, setOrganizationId] = useState<string | null | undefined>(undefined);

    useEffect(() => {
        const unsubscribe = authStore.subscribe(() => {
            const updatedOrganizationId = authStore.getState().organizationId;
            setOrganizationId(updatedOrganizationId);
        });

        const initialOrganizationId = authStore.getState().organizationId;
        setOrganizationId(initialOrganizationId);

        return () => {
            unsubscribe();
        };
    }, []);

    useEffect(() => {
        if (isAuthenticated !== undefined && organizationId !== undefined) {
            setIsLoading(false);
        }
    }, [isAuthenticated, organizationId]);

    useEffect(() => {
        if (!isLoading && !isAuthenticated) {
            signIn(window.location.origin + "/sign-in-callback");
        }
    }, [isLoading, isAuthenticated, signIn]);

    if (isLoading) return <Spinner />;
    
    // if (!organizationId) {
    //     window.location.href = "https://staging.hub.canaia.ai/welcome"
    // }

    return <Outlet />;
};

export default ProtectedRoute;
