import {useHandleSignInCallback} from "@logto/react";
import {useNavigate} from "react-router-dom";
import { useTranslation } from "react-i18next";


function SignInCallback() {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const { isLoading } = useHandleSignInCallback(() => {
        navigate("/")
    })

    // When it's working in progress
    if (isLoading) {
        return <div>{t('redirection')+ '...'}</div>
    }
}

export default SignInCallback
