import apiClient from '../../ApiClients';
import { KnowledgeBaseItem } from '../../../pages/KnoweledgeBases/KnowledgeBaseItem.types';


export interface SearchAssistantsParams {
    q: string; 
    page?: number;
    size?: number;
  }
  
  
export const searchAssistants = async (
    params: SearchAssistantsParams,
    token: string,
    signOut: () => Promise<void>,
    showToast: (message: string) => void
): Promise<KnowledgeBaseItem> => {

    const axiosInstance = apiClient(token, signOut, showToast);
    const response = await axiosInstance.get<KnowledgeBaseItem>('/assistants/search', {
        params: {
            q: params.q,
            page: params.page || 1,
            size: params.size || 10,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    return response.data;
};
